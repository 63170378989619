import { wait } from "@testing-library/user-event/dist/utils";

import { ResumeBrief, ResumeStatus } from "../../../models/resume";
import request from "../../request";

/**
 * - group和turn不指定时(或者为0)为用户所在组和所有轮次
 * - 当keyword缺省时，约束条件为turn与group
 * - 当keyword存在时，参数group将被忽略
 */
export type GetResumeListReqData = {
  group?: number;
  keyword?: string;
  turn?: number;
  period: number | null;
};

export const getResumeListPath = "/resume";

/**
 * 获得简历简略信息的列表
 */
export async function getResumeListReq(
  params: GetResumeListReqData
): Promise<ResumeBrief[] | null> {
  if (isNaN(params.group || NaN) || params.period === null) return null;
  const res = await request<ResumeBrief[]>({
    url: getResumeListPath,
    method: "GET",
    params,
  });

  return res;
}
