import { useEffect, useState } from "react";
import { useAttachments } from "../../api/swr/attachment/useAttachments";
import type { SelectedValues } from "../../models/attachment";
import { CheckedValues } from "../../models/attachment";
import BottomBar from "./components/BottomBar";
import Lists from "./components/Lists";
import Navigation from "./components/Navigation";
import "./index.scss";

// const attachmentItems: AttachmentItem[] = [
//   {
//     name: "张三",
//     attachment: [
//       {
//         label: "附件1",
//         value: "https://tx.cdn.firlin.cn/building1.png",
//       },
//       {
//         label: "附件2",
//         value: "https://tx.cdn.firlin.cn/ghost-cry.png",
//       },
//     ],
//   },
//   {
//     name: "李四",
//     attachment: [
//       {
//         label: "附件1",
//         value: "https://tx.cdn.firlin.cn/foodGeneral/static/ghost-bread.png",
//       },
//       {
//         label: "附件2",
//         value: "https://tx.cdn.firlin.cn/foodGeneral/static/s-swim.png",
//       },
//     ],
//   },
// ];

export default function AttachmentBox() {
  // 选择的组别和轮次
  const [selectedValues, setSelectedValues] = useState<
    SelectedValues | undefined
  >();

  // 获得 attachmentItems
  const { attachmentItems, error } = useAttachments({
    turn: selectedValues?.turn,
  });

  // 勾选的附件值
  const [checkedValues, setCheckedValues] = useState<CheckedValues>({});
  // 全部的附件值，用来比较单个组别的附件是否全选
  const [allAttachmentValues, setAllAttachmentValues] = useState<CheckedValues>(
    {}
  );

  // 初始化为全勾选
  useEffect(() => {
    // 只有在 attachmentItems 有值的时候才初始化
    if (attachmentItems) {
      const obj: CheckedValues = {};
      attachmentItems.forEach((item) => {
        obj[item.name] = item.attachment?.map((attachment) => attachment.value);
      });
      setCheckedValues(obj);
      setAllAttachmentValues(obj);
    }
  }, [attachmentItems]);

  return (
    <div className="attachment">
      <div className="attachment-nav-container">
        <Navigation
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
      </div>
      <div className="attachment-lists-container">
        <div>
          <Lists
            attachmentItems={attachmentItems}
            checkedValues={checkedValues}
            setCheckedValues={setCheckedValues}
            allAttachmentValues={allAttachmentValues}
          />
        </div>
        <div className="attachment-lists-bottom-bar">
          <BottomBar
            checkedValues={checkedValues}
            allAttachmentValues={allAttachmentValues}
            setCheckedValues={setCheckedValues}
          />
        </div>
      </div>
    </div>
  );
}
