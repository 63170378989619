import { DataType } from "../index";

const stateTransformer = {
  disabled: "未通知",
  error: "淘汰",
  success: "通过",
  filled: "已填写",
  unfilled: "未填写",
};

export const dataFilter = (key: string, data: DataType[]) => {
  if (!key) return data;
  const reg = new RegExp(key, "g");
  const filterData: DataType[] = [];

  data.forEach((interview) => {
    if (
      reg.test(interview.name) ||
      reg.test(interview.major) ||
      reg.test(stateTransformer[interview.state])
    ) {
      filterData.push(interview);
    } else if (interview.tags?.some((tagObj) => reg.test(tagObj.tag))) {
      filterData.push(interview);
    }
  });

  return filterData;
};
