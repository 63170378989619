import { PeriodWork } from "../../../models/period";
import request from "../../request";

export const getPeriodWorkPath = "/association/period/current/todo";

/**
 * 获得首页的本组工作数据
 * @returns
 */
export async function getPeriodWorkReq() {
  const res = await request<PeriodWork>({
    url: getPeriodWorkPath,
    method: "GET",
  });

  return res;
}
