import "./style/index.scss";
import "./mobx";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";

try {
  ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter basename="/resume">
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById("root")
  );
} catch (e) {
  console.error("error in index:");
  console.error(e);
  alert("请在最新版 Chrome 浏览器上查看此网站");
}
