import "./index.scss";

import { spawn } from "child_process";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

import { IconHelpCircle, IconSearch } from "@douyinfe/semi-icons";
import { AutoComplete, Form, Modal, Tag, Tooltip } from "@douyinfe/semi-ui";
import { FormApi } from "@douyinfe/semi-ui/lib/es/form";

import { getCurInterviewersPath } from "../../api/http/notify/getCurInterviewers";
import {
  sendNoticeReq,
  SendNoticeReqData,
} from "../../api/http/notify/sendNotice";
import { useSchedulesOfMyGroup } from "../../api/swr/schedule/useSchedulesOfMyGroup";
import { useTemplates } from "../../api/swr/template/useTemplates";
import { useCurTurnInfo } from "../../api/swr/turn/useCurTurnInfo";
import Button from "../../components/Button";
import CEmpty from "../../components/CEmpty";
import EditableDiv from "../../components/EditableDiv";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { NotifyListStoreStore } from "../../mobx/notifyList";
import { NoticeForm } from "../../models/notice";
import { ScheduleBrief } from "../../models/schedule";
import { NoticeTemplateType } from "../../models/template";
import { showToast } from "../../utils/showToast";
import PopoverItem from "./components/PopoverItem";
import { mapToAutoCompleteItem } from "./utils/mapToAutoCompleteItem";
import { useScheduleDetail } from "../../api/swr/schedule/useScheduleDetail";

/** 给 AutoComplete 组件添加上合适的类型 */
class AutoCompleteTemplate extends AutoComplete<NoticeTemplateType> {}
class AutoCompleteSchedule extends AutoComplete<ScheduleBrief> {}

type FormFields = Omit<NoticeForm, "notify_id_list" | "passed"> & {
  noticeList: { id: number; name: string }[];
  title: string;
  shouldSendSchedule: boolean;
  passed?: "true" | "false";
};

function _CreateNotice() {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const formApi = useRef<FormApi<FormFields>>();
  const forceUpdate = useForceUpdate();

  const { templates: _templates, loading: loadingTemplates } = useTemplates();
  const templates = mapToAutoCompleteItem(_templates || []);

  const { loading: loadingSchedules, schedules: _schedules } =
    useSchedulesOfMyGroup();
  const { scheduleDetail, loading: loadingScheduleDetail } =
    useScheduleDetail();

  const schedules = mapToAutoCompleteItem(_schedules || []);

  const { turnInfo } = useCurTurnInfo();

  /** 将全局被通知人信息同步到 form 中 */
  formApi.current?.setValue("noticeList", NotifyListStoreStore.value);

  const form = formApi.current?.getValues();

  async function handleSubmit() {
    const formInFunc = formApi.current?.getValues();
    if (!formInFunc) return;

    const {
      content,
      email,
      passed,
      shouldSendSchedule,
      noticeList,
      sms,
      title,
      schedule_id,
    } = formInFunc;

    const submitData: Partial<SendNoticeReqData> = {};

    if (content?.trim()?.length > 0) {
      submitData.content = content;
    } else {
      return showToast("通知内容不能为空", "error");
    }
    if (passed === undefined)
      return showToast("请选择这部分面试者是否通过本轮", "error");

    submitData.email = email;
    submitData.sms = sms;
    submitData.passed = passed === "true"; // 是否通过

    if (!submitData.passed && shouldSendSchedule)
      return showToast("不能在淘汰时发送时间安排问卷", "error");
    if (noticeList.length === 0) return showToast("通知人不能为空", "error");
    submitData.notify_id_list = noticeList.map((i) => i.id);

    if (shouldSendSchedule)
      if (scheduleDetail) submitData.schedule_id = scheduleDetail.id;
      else return showToast("未创建时间安排表", "error");
    /* if (shouldSendSchedule && schedule_id !== undefined)
      submitData.schedule_id = schedule_id; */

    const _confirm = await new Promise((resolve) => {
      Modal.confirm({
        title: submitData.passed
          ? "确定让这些人通过本轮吗"
          : "确定淘汰这些人吗?",
        content: submitData.passed
          ? "不要让人空欢喜一场..."
          : "有的人一旦淘汰就不再...",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
    if (!_confirm) return;

    const success = await sendNoticeReq(submitData as SendNoticeReqData);

    if (!success) return;

    showToast("已发送通知!", "success");
    mutate(`${getCurInterviewersPath}/${turnInfo?.id}?notify_type=undefined`);

    navigate("/notice");
    NotifyListStoreStore.value = [];
  }

  return (
    <Form
      className="scroll_container"
      getFormApi={(api) => (formApi.current = api)}
      initValues={{
        content: "",
        email: false,
        noticeList: [],
        sms: false,
        title: "",
        shouldSendSchedule: false,
      }}
    >
      {formApi.current && form && (
        <div className="create_notice">
          <div className="create_notice-header">
            <div className="create_notice-header-title">新建通知</div>
          </div>

          <div className="create_notice-settings time">
            <div className="create_notice-settings-name">
              <Form.Checkbox
                field="shouldSendSchedule"
                noLabel
                disabled={
                  scheduleDetail && !scheduleDetail?.open ? true : false
                }
                onChange={() => {
                  forceUpdate();
                }}
              >
                时间安排
              </Form.Checkbox>
            </div>
            <div className="create_notice-settings-detail-container">
              {form.shouldSendSchedule && form.schedule_id !== undefined && (
                <div className="create_notice-settings-detail-tips">
                  你已添加了问卷，是否要为问卷设置定时关闭时间
                  <div className="create_notice-settings-detail-tips-button">
                    设置定时关闭
                  </div>
                </div>
              )}
              {!loadingScheduleDetail ? (
                <Button
                  onClick={() => {
                    if (scheduleDetail && !scheduleDetail?.open) return;
                    Modal.confirm({
                      onOk: () => navigate("/notice/create-time"),
                      title: "跳转后表单内容不会保存",
                      content: "确定跳转吗?",
                    });
                  }}
                  content={
                    !scheduleDetail
                      ? "创建安排表"
                      : !scheduleDetail?.open
                      ? "时间安排表已关闭 需删除当前时间安排表才可重新创建"
                      : "已创建时间安排表 点击查看或编辑"
                  }
                  type="filled"
                />
              ) : null}
            </div>
          </div>
          <div className="create_notice-settings">
            <div className="create_notice-settings-name">通知人</div>
            <div className="create_notice-settings-detail interviewers">
              {form.noticeList.map((item) => (
                <Tag
                  onClose={() => {
                    NotifyListStoreStore.value =
                      NotifyListStoreStore.value.filter(
                        (v) => v.id !== item.id
                      );
                    forceUpdate();
                  }}
                  key={item.id}
                  closable
                  color="blue"
                  type="ghost"
                >
                  {item.name}
                </Tag>
              ))}
              {form.noticeList.length === 0 && (
                <span className="t-e">
                  无被通知人, 请去
                  <NavLink to={"/notice"} className="t-e t-u t-b">
                    创建通知界面
                  </NavLink>
                  勾选通知人
                </span>
              )}
            </div>
          </div>
          <div className="create_notice-settings">
            <div className="create_notice-settings-name">
              <Tooltip content="此项将关系到面试者是否被淘汰，同一轮的多次通知以最后一次结果为准">
                是否通过本轮 <IconHelpCircle />
              </Tooltip>
            </div>
            <div className="create_notice-settings-detail out">
              <Form.RadioGroup onChange={forceUpdate} noLabel field="passed">
                <Form.Radio value={"true"}>通过</Form.Radio>
                <Form.Radio value={"false"}>淘汰</Form.Radio>
              </Form.RadioGroup>
            </div>
          </div>
          <div className="create_notice-settings">
            <div className="create_notice-settings-name">
              <Tooltip content='仅作为下方通知内容的默认值, 以"通知内容"所填为准'>
                平台通知模板 <IconHelpCircle />
              </Tooltip>
            </div>
            <AutoCompleteTemplate
              loading={loadingTemplates}
              emptyContent={<CEmpty />}
              data={templates}
              renderSelectedItem={(o) => o?.title || ""}
              onSelect={(o) => {
                if (o.template_id !== undefined)
                  formApi.current?.setValue("content", o.content);
                forceUpdate();
              }}
              onSelectWithObject
              renderItem={(i) => (
                <PopoverItem
                  content={i.content}
                  groupName={i.group_name}
                  title={i.title}
                  type={i.type}
                />
              )}
              className="create_notice-settings-detail auto_complete"
              placeholder="选择通知模板"
              suffix={<IconSearch />}
              value={
                templates
                  .reverse()
                  .find(
                    (t) => t.content === formApi.current?.getValue("content")
                  )?.title
              }
            />
          </div>
          <div className="create_notice-settings">
            <div className="create_notice-settings-name">
              <Tooltip content="将被展示到报名者端的招新系统网站上(不会以邮件或短信形式发送)">
                通知内容 <IconHelpCircle />
              </Tooltip>
            </div>
            <Form.TextArea
              field="content"
              noLabel
              autosize
              rows={1}
              className="create_notice-settings-detail input"
              onChange={forceUpdate}
            />
          </div>

          <div className="create_notice-channel">
            <div className="create_notice-channel-item">
              <Form.Checkbox noLabel field="email">
                <Tooltip content="仅会通知报名者前往招新系统报名端网页查看结果">
                  邮件提醒
                </Tooltip>
              </Form.Checkbox>
              <div className="create_notice-channel-item-content">
                {form.passed === undefined
                  ? "请选择这些同学是否通过"
                  : form.passed === "true"
                  ? "xxx，你好！恭喜你！你通过了xx组招新的第 x 轮！具体信息请登录招新平台查看。"
                  : "xxx，你好！很遗憾，你未能通过xx组招新的第 x 轮，具体信息请登录招新平台查看。"}
              </div>
            </div>
            <div className="create_notice-channel-item">
              <Form.Checkbox noLabel field="sms">
                <Tooltip content="仅会通知报名者前往招新系统报名端网页查看结果">
                  短信提醒
                </Tooltip>
              </Form.Checkbox>
              <div className="create_notice-channel-item-content">
                【冰岩作坊】来自冰岩作坊的通知：xxx，你好！您的招新状态已更新，请登录招新平台（https://cvs.bingyan.net/sign）查看详情。
              </div>
            </div>
          </div>
          <div className="create_notice-buttons">
            {/* <Button content="保存草稿" /> */}
            <Button content="发送" type="filled" onClick={handleSubmit} />
          </div>
        </div>
      )}
    </Form>
  );
}

const CreateNotice = observer(_CreateNotice);

export default CreateNotice;
