import "./index.scss";


import { User, UserManager } from "oidc-client-ts";
import React, { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { getSelfPath } from "../../../../api/http/member/getSelf";
import Button from "../../../../components/Button";
import { oidcConfig } from "../../../../config/oidcConfig";
import { TOKEN_KEY } from "../../../../constants/token";
import { useJumpTo } from "../../../../hooks/useJumpTo";
import { showToast } from "../../../../utils/showToast";
import { SERVER_BASE_URL } from "../../../../api/config";
const userManager = new UserManager(oidcConfig);

const baseUrl = SERVER_BASE_URL;

const OidcClient: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const swr = useSWRConfig();
  const jumpTo = useJumpTo();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");

      if (code && state) {
        try {
          const response = await fetch(
            `${baseUrl}/user/oidc/callback?code=${code}&state=${state}`,
            {
              method: "GET",
            }
          );
          if (!response.ok) {
            throw new Error(`登录失败！错误码：${response.status}`);
          }
          const data = await response.json();
          // 无法获取到 token
          if (!data || !data.data || !data.data.token) {
            throw new Error("登录失败！未返回 token!");
          }
          localStorage.setItem(TOKEN_KEY, data.data.token);
          showToast("登录成功!", "success");
          swr.mutate(getSelfPath);
          jumpTo("/home", {});
        } catch (error) {
          showToast("登录失败!", "error");
          jumpTo("/login", {}, true);
        }
      }
    };

    handleCallback();
  }, []);

  const handleLogin = () => {
    userManager.signinRedirect();
  };

  const handleLogout = () => {
    userManager.signoutRedirect();
  };


  return (
    <div>
      <div className="p-login-btn-oidc">
        <img src="https://sso.bingyan.net/assets/bingyan-line-bae559f0.png" />
        <Button onClick={handleLogin}>冰岩 SSO 登录</Button>
      </div>
    </div>
  );
};

export default OidcClient;
