import { useEffect, useMemo, useState } from "react";

import { useScheduleDetail } from "../../../api/swr/schedule/useScheduleDetail";
import { resultTransformer } from "../utils/resultTransformer";

export const useScheduleInfo = () => {
  const scheduleInfo = useScheduleDetail();

  const questionnaireResults = useMemo(() => {
    const originResults =
      scheduleInfo &&
      scheduleInfo.scheduleDetail &&
      scheduleInfo.scheduleDetail.timeframes
        ? resultTransformer(
            scheduleInfo.scheduleDetail.timeframes,
            scheduleInfo.scheduleDetail?.duration
          )
        : [];
    return originResults;
  }, [scheduleInfo && scheduleInfo.scheduleDetail]);

  const notArrange = useMemo(() => {
    const originNotArrange =
      scheduleInfo &&
      scheduleInfo.scheduleDetail &&
      scheduleInfo.scheduleDetail.not_arrange
        ? scheduleInfo.scheduleDetail.not_arrange.map((result) => ({
            ...result,
            resume_id: result.resume_id.toString(),
            duration: scheduleInfo.scheduleDetail?.duration,
          }))
        : [];
    return originNotArrange;
  }, [
    scheduleInfo &&
      scheduleInfo.scheduleDetail &&
      scheduleInfo.scheduleDetail.not_arrange,
  ]);

  const [results, setResults] = useState({ questionnaireResults, notArrange });

  useEffect(() => {
    setResults({ questionnaireResults, notArrange });
  }, [questionnaireResults, notArrange]);

  const questionnaireInfo = scheduleInfo.scheduleDetail && {
    closeTime: scheduleInfo.scheduleDetail?.close_time,
    id: scheduleInfo.scheduleDetail?.id || 0,
    open: scheduleInfo.scheduleDetail?.open || false,
    title: scheduleInfo.scheduleDetail?.title || "",
  };

  return { results, questionnaireInfo };
};
