import "./index.scss";

import { FC } from "react";

import { Skeleton } from "@douyinfe/semi-ui";

import { ResumeDetail } from "../../../../../models/resume";
import AnswerItem from "./components/AnswerItem";

type ResumeAnswerTabProps = {
  answers: ResumeDetail["custom"] | undefined | null;
};

const ResumeAnswerTab: FC<ResumeAnswerTabProps> = ({ answers }) => {
  if (!answers) {
    return (
      <Skeleton
        active
        placeholder={Array.from({ length: 3 }).map((_, i) => (
          <div key={i} style={{ margin: "1.5rem 0" }}>
            <Skeleton.Title style={{ width: 150, marginBottom: "0.8rem" }} />
            <Skeleton.Paragraph rows={3} />
          </div>
        ))}
      />
    );
  }

  return (
    <div className="r_ans">
      {answers.map((a, ind) => (
        <AnswerItem key={ind} QA={a}></AnswerItem>
      ))}
    </div>
  );
};

export default ResumeAnswerTab;
