import useSWR from "swr";

import {
  getCurTurnInfoPath,
  getCurTurnInfoReq,
} from "../../http/turn/getCurTurnInfo";
import { useSelf } from "../member/useSelf";

/** 获得当前轮次信息 */
export function useCurTurnInfo() {
  const self = useSelf();

  const { isValidating, mutate, data, error } = useSWR(
    `${getCurTurnInfoPath}?group=${self?.self?.group_id}`,
    () => getCurTurnInfoReq(self?.self?.group_id)
  );

  return {
    turnInfo: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
