import { makeAutoObservable } from "mobx";

/**
 * 要通知的名单
 */
class NotifyList {
  /** 个人信息 */
  value: { name: string; id: number; phone: string }[] = [];

  constructor() {
    makeAutoObservable(this);
  }
}

export const NotifyListStoreStore = new NotifyList();
