import { useMemo } from "react";
import useSWR from "swr";

import { useCurPeriod } from "../../../hooks/useCurPeriod";
import { useQuery } from "../../../hooks/useQuery";
import { ResumeBrief } from "../../../models/resume";
import { SortByOptsType, sortStrategyMap } from "../../../pages/Resume/ResumeNav";
import {
    getResumeListPath, getResumeListReq, GetResumeListReqData
} from "../../http/resume/getResumeList";
import { usePeriods } from "../association/usePeriods";

export function useResumeList(filter: GetResumeListReqData) {
  const { isValidating, mutate, data, error } = useSWR(
    `${getResumeListPath}?group=${filter.group}&kw=${filter.keyword}&turn=${filter.turn}&period=${filter.period}`,
    () => getResumeListReq(filter)
  );

  return {
    resumes: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}

/**
 * ## 根据当前路由 query 中的 groupID 字段自动获得当前组别的简历列表
 * **多次调用只会发一次请求, 所以在任何需要简历列表的地方都可以放心大胆的直接用**
 * @returns
 * - `null`: 请求出错或路由找不到 id 的参数
 * - `undefined`: 请求中
 * - `ResumeBrief[]`: 请求到的结果, 按是否通过排序
 */
export function useCurResumeList(): null | ResumeBrief[] | undefined {
  const { groupID: groupIdStr, kw, sort } = useQuery();

  const curPeriod = useCurPeriod();

  const groupID = parseInt(groupIdStr || "");
  const { resumes, error, loading } = useResumeList({
    keyword: kw,
    group: groupID,
    period: curPeriod?.id || null,
  });

  const compareFn = sortStrategyMap[(sort as SortByOptsType) || "报名时间"];

  const memoedResumes = useMemo(() => {
    if (!resumes) return [];

    const sortedResumes = [...resumes].sort((a, b) =>
      a.has_out === b.has_out
        ? compareFn?.(a, b) ?? 0
        : Number(a.has_out) - Number(b.has_out)
    );

    return sortedResumes;
  }, [resumes, groupID, kw, curPeriod?.id, compareFn]);

  if (error) return null;
  if (loading) return undefined;

  return memoedResumes;
}
