import "./index.scss";

import { FC } from "react";

import { Skeleton } from "@douyinfe/semi-ui";

import { useCurResumeList } from "../../../../../../../api/swr/resume/useResumeList";
import { useJumpTo } from "../../../../../../../hooks/useJumpTo";
import { useQuery } from "../../../../../../../hooks/useQuery";
import { SwitchFn } from "../../../../../../../utils/tsHelper.model";

type SwitchProps = {};

const Switch: FC<SwitchProps> = () => {
  const resumes = useCurResumeList();
  const { resumeID } = useQuery();
  const jumpTo = useJumpTo();

  if (!resumes)
    return (
      <Skeleton
        active
        placeholder={
          <div className="r-rnc-switch">
            <Skeleton.Button className="r-rnc-switch-btn"></Skeleton.Button>
            <Skeleton.Button className="r-rnc-switch-btn"></Skeleton.Button>
          </div>
        }
      />
    );

  const switchResume: SwitchFn = (dir) => {
    if (!resumes || resumes.length === 0) return;

    const currentIndex = resumes.findIndex(
      (r) => r.id === parseInt(resumeID || "")
    );
    if (currentIndex === -1) return;

    const targetIndex =
      (currentIndex + (dir === "next" ? 1 : -1) + resumes.length) %
      resumes.length;
    const targetID = resumes[targetIndex].id;

    jumpTo("/resume", { resumeID: targetID });
  };

  return (
    <div className="r-rnc-switch">
      <div className="r-rnc-switch-btn" onClick={() => switchResume("prev")}>
        上一个
      </div>
      <div className="r-rnc-switch-btn" onClick={() => switchResume("next")}>
        下一个
      </div>
    </div>
  );
};

export default Switch;
