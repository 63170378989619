/**
 * 按钮组件
 *
 * @component
 * @example
 * ```tsx
 * import Button from './Button';
 *
 * function App() {
 *   return (
 *     <Button type="filled" color="primary" onClick={() => console.log('Button clicked')}>
 *       确定
 *     </Button>
 *   );
 * }
 * ```
 */
import "./index.scss";

import { IconSpin } from "@douyinfe/semi-icons";

/**
 * 按钮组件的属性
 */
export type ButtonProps = {
  /**
   * 自定义类名
   */
  className?: string;
  /**
   * 按钮类型
   */
  type?: "outline" | "filled" | "light_filled";
  /**
   * 按钮颜色
   */
  color?: "primary" | "error" | "success" | "disabled";
  /**
   * 是否显示加载状态
   */
  loading?: boolean;
  /**
   * 点击事件回调函数
   */
  onClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement> &
  /**
   * 按钮内容，只能传入 content 或 children 其中之一
   */
  (| {
        /**
         * 按钮内容
         */
        content?: never;
        /**
         * 按钮子元素
         */
        children: React.ReactNode;
      }
    | {
        /**
         * 按钮内容
         */
        content: string;
        /**
         * 按钮子元素
         */
        children?: never;
      }
  );

/**
 * 按钮组件
 *
 * @param {ButtonProps} props - 按钮组件的属性
 * @returns {JSX.Element} 按钮组件
 */
export default function Button(props: ButtonProps) {
  const {
    content,
    type = "outline",
    color = "primary",
    onClick,
    className = "",
    children,
    loading,
    ...rest
  } = props;

  return (
    <div
      className={`r-button ${type} ${color} ${className}`}
      onClick={color === "disabled" ? undefined : onClick}
      {...rest}
    >
      {loading ? <IconSpin spin /> : children || content}
    </div>
  );
}
