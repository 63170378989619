import useSWR from "swr";

import { useCurPeriod } from "../../../hooks/useCurPeriod";
import { getPeriodDetailReq } from "../../http/association/getCurrentPeriodInfo";

export function usePeriodDetail() {
  const curPeriod = useCurPeriod();
  const { isValidating, mutate, data, error } = useSWR(
    `/association/period/${curPeriod?.id}/detail`,
    () => getPeriodDetailReq(curPeriod?.id)
  );

  return {
    curPeriodInfo: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
