import { Schedule } from "../../../models/schedule";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getScheduleDetailPath = "/schedule";

/**
 * 获得本组本轮的时间表
 * @returns
 */
export async function getScheduleDetailReq(
  id?: number
): Promise<Schedule | null> {
  if (!id) return null;

  // await wait(1000);

  // return {
  //   id: 1,
  //   title: "标题",
  //   group_id: 111,
  //   create_time: 123456,
  //   // close_time: 1647388861, //关闭时间
  //   open: true,
  //   timeframes: [
  //     {
  //       timeframe_id: 1,
  //       capacity: 4,
  //       time: "X月X日01:00-02:00", //字符串
  //       resumes: [
  //         {
  //           resume_id: 11,
  //           name: "小白",
  //         },
  //       ],
  //     },
  //   ],
  //   not_arrange: [
  //     {
  //       resume_id: 22,
  //       name: "小黑",
  //     },
  //   ],
  // };

  const res = await request<Schedule>({
    url: `${getScheduleDetailPath}/${id}`,
    method: "GET",
  });

  return res;
}
