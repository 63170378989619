import "./index.scss";

import { useNavigate } from "react-router-dom";

import { IconChevronLeft, IconSearch } from "@douyinfe/semi-icons";
import { Input } from "@douyinfe/semi-ui";

import { usePeriods } from "../../api/swr/association/usePeriods";
import OverviewTotal, {
  OverviewTotalProps,
} from "../Home/components/OverviewTotal";

export default function History() {
  const { periods } = usePeriods();
  const navigate = useNavigate();

  return (
    <div className="history">
      <div className="history-opts">
        <div className="history-opts-back">
          <IconChevronLeft
            onClick={() => navigate(-1)}
            className="history-opts-back-icon"
          />
          历史招新
        </div>
        <Input
          prefix={<IconSearch className="r-input-icon" />}
          className="r-input"
          placeholder="搜索名称"
        ></Input>
      </div>
      {(periods || Array.from({ length: 3 })).map((period, index) => (
        <OverviewTotal key={period ? period.id : index} periodInfo={period} />
      ))}
    </div>
  );
}
