import "./index.scss";

import { FC } from "react";

import { TabPane, Tabs } from "@douyinfe/semi-ui";

import ChatRoom from "./components/Chatroom";
import RateNComment from "./components/RateNComment";
import { TabKey, useCurTab } from "./hooks/useCurTab";

type ResumeActionsProps = {};

const ResumeActions: FC<ResumeActionsProps> = () => {
  const { actionTab, setActiveKey, tabKeys } = useCurTab();
  return (
    <div className="resume-actions">
      {/* <Tabs
        type="line"
        activeKey={actionTab}
        onChange={(k) => setActiveKey(k as TabKey)}
      >
        <TabPane tab={tabKeys[0]} itemKey={tabKeys[0]}>
          
        </TabPane>
        <TabPane tab={tabKeys[1]} itemKey={tabKeys[1]}>
          <ChatRoom></ChatRoom>
        </TabPane>
      </Tabs> */}

      <RateNComment></RateNComment>
    </div>
  );
};

export default ResumeActions;
