import { useMemo, useState } from "react";

import { ScheduleEdit, TimeFrameEdit } from "../../../models/schedule";

export function useTimeFrames() {
  const [_timeFrames, setTimeFrames] = useState<
    (TimeFrameEdit & { duration: number })[]
  >([]);

  const timeFrames = _timeFrames.sort(
    (f1, f2) => f1.start_time - f2.start_time
  );

  const hasConflict = useMemo(() => {
    let flag = false;
    timeFrames.reduce((pre, cur) => {
      // pre 为上一轮次的截至时间
      if (pre >= cur.start_time) flag = true;
      return cur.start_time + cur.duration;
    }, 0);
    return flag;
  }, [_timeFrames]);

  const setTimeFrame = (
    newTimeFrame: Partial<TimeFrameEdit> & Pick<TimeFrameEdit, "timeframe_id">
  ) => {
    const toUpdateIndex = timeFrames.findIndex(
      (t) => t.timeframe_id === newTimeFrame.timeframe_id
    );

    // TODO send request

    setTimeFrames(
      timeFrames.map((t, index) =>
        index === toUpdateIndex ? { ...t, ...newTimeFrame } : t
      )
    );
  };

  const delTimeFrame = (id: number) => {
    setTimeFrames(timeFrames.filter((t) => t.timeframe_id !== id));
    // TODO send request
  };

  const _addTimeFrame = async (
    timeFrame: Pick<TimeFrameEdit, "amount" | "start_time"> & {
      duration: number;
    }
  ) => {
    // TODO send request
    setTimeFrames((timeFrames) => [
      ...timeFrames,
      { ...timeFrame, timeframe_id: new Date().getTime() + Math.random() },
    ]);
  };

  const addTimeFrame = async (
    startTime: number,
    turns: number,
    schedule: ScheduleEdit
  ) => {
    const { duration, gap_time } = schedule;

    for (let i = 0; i < turns; i++) {
      _addTimeFrame({
        amount: 1,
        start_time: startTime + i * (duration + gap_time),
        duration,
      });
    }
  };

  const clearTimeFrames = () => {
    setTimeFrames([]);
    // TODO 发请求
  };

  return {
    timeFrames,
    setTimeFrame,
    delTimeFrame,
    addTimeFrame,
    clearTimeFrames,
    hasConflict,
  };
}
