import { MemberInfo } from "../../../models/member";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getSelfPath = "/user/me";

/**
 * 获得自己的用户信息
 * @returns
 */
export async function getSelfReq(): Promise<MemberInfo | null> {
  const res = await request<MemberInfo>(
    {
      url: getSelfPath,
      method: "GET",
    },
    { notShowHint: true }
  );

  return res;
}
