import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useJumpTo } from "../../../../hooks/useJumpTo";
import { useQuery } from "../../../../hooks/useQuery";

const tabKeys = ["报名表", "评论"] as const;
export type TabKey = typeof tabKeys[number];

export function useCurTab() {
  const jumpTo = useJumpTo();

  const mainTab = useQuery().mainTab;

  function setActiveKey(mainTab: TabKey) {
    jumpTo("/resume", { mainTab }, false, true);
  }

  useEffect(() => {
    if (!mainTab) {
      setActiveKey(tabKeys[0]);
    }
  }, [mainTab]);

  return { mainTab, setActiveKey, tabKeys };
}
