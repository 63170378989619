/**
 * 高阶函数，作用为： semi DatePicker 组件禁止某日期之前的日期选择
 * @param endDate 允许日期的起点，会禁用此日期前的日期
 * @returns 给 DatePicker 组件的 disabledDate 属性赋值
 */
export function getDisabledDate(endDate: Date) {
  return (date?: Date) => {
    if (!date) return false;
    return date.getTime() < new Date(endDate.toDateString()).getTime();
  };
}

/**
 * 高阶函数，作用为： semi DatePicker 组件禁止某时间之前的时间选择
 * @param endDate 允许时间的起点，会禁用此日期前的时间
 * @returns 给 DatePicker 组件的 getDisableTime 属性赋值
 */
export function getDisableTime(endDate: Date) {
  return (date?: Date | Date[]) => {
    if (!date || Array.isArray(date)) return {};
    const isToday =
      new Date(date.toDateString()).getTime() ===
      new Date(endDate.toDateString()).getTime();
    if (!isToday) return {};

    const hours = date.getHours(),
      minutes = date.getMinutes();

    const curHours = endDate.getHours(),
      curMinutes = endDate.getMinutes(),
      curSeconds = endDate.getSeconds();

    const disabledHours = () =>
      Array.from({
        length: curHours,
      }).map((_, index) => index);

    const disabledMinutes = () =>
      curHours === hours
        ? Array.from({
            length: curMinutes,
          }).map((_, index) => index)
        : [];

    const disabledSeconds = () =>
      curHours === hours && curMinutes === minutes
        ? Array.from({
            length: curSeconds,
          }).map((_, index) => index)
        : [];

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  };
}
