import { NoticeTemplateType, NotifyType } from "../../../models/template";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getTemplatesPath = "/notify/template";

/**
 * 获得所有通知模板
 */
export async function getTemplatesReq(): Promise<null | NoticeTemplateType[]> {
  const res = await request<NoticeTemplateType[]>({
    url: getTemplatesPath,
    method: "GET",
  });

  return res;
}
