import useSWR from "swr";

import { getPeriodsPath, getPeriodsReq } from "../../http/association/getPeriods";

export function usePeriods() {
  const { isValidating, mutate, data, error } = useSWR(
    getPeriodsPath,
    getPeriodsReq
  );

  return {
    periods: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
