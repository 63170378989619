import { useEffect } from "react";

import { useGroupList } from "../../../../api/swr/group/useGroupList";
import { useSelf } from "../../../../api/swr/member/useSelf";
import { useJumpTo } from "../../../../hooks/useJumpTo";
import { useQuery } from "../../../../hooks/useQuery";

export function useGroup() {
  /** 所有组别 */
  const { groupList, loading, error } = useGroupList();

  /** 当前组别 */
  const query = useQuery();
  const groupID = parseInt(query.groupID || "");
  const curGroup = groupList && groupList.find((g) => g.id === groupID);

  // 自己的组别
  const { self } = useSelf();

  const jumpTo = useJumpTo();
  /** 切换组别 */
  function setCurGroup(groupID: number) {
    jumpTo("/resume", { groupID }, false, true);
  }

  useEffect(() => {
    // 没有组别列表就啥都不干
    if (!groupList || groupList.length === 0) return;
    // 有组别列表但是没有组别, 就自动设为第一组
    if (!curGroup) {
      if (self) jumpTo("/resume", { groupID: self.group_id }, false, true);
      else jumpTo("/resume", { groupID: groupList[0].id }, false, true);
    }
  }, [curGroup, groupList]);

  return {
    groupList,
    curGroup,
    setCurGroup,
    loading: Boolean(loading || error || !groupList),
  };
}
