import { Checkbox, CheckboxGroup, Collapse } from "@douyinfe/semi-ui";
import { CheckboxEvent } from "@douyinfe/semi-ui/lib/es/checkbox";
import type {
  AttachmentItem,
  CheckedValues
} from "../../../../models/attachment";
import "./index.scss";

export default function Lists({
  attachmentItems,
  checkedValues,
  setCheckedValues,
  allAttachmentValues,
}: {
  attachmentItems: AttachmentItem[] | null | undefined;
  checkedValues: CheckedValues;
  setCheckedValues: (checkedValues: { [key: string]: string[] }) => void;
  allAttachmentValues: CheckedValues;
}) {
  // 如果没有附件项就返回null
  if (!attachmentItems) return null;

  const defaultActiveKey = attachmentItems.map((item, index) => {
    return index.toString();
  });

  // 处理 CheckboxGroup 的附件项的勾选
  const handleCheckboxGroupChange = (val: string[], name: string) => {
    setCheckedValues({ ...checkedValues, [name]: val });
  };

  // 处理名字 Checkbox 的勾选
  const handleCheckBoxChange = (e: CheckboxEvent) => {
    setCheckedValues({
      ...checkedValues,
      [e.target.value]: e.target.checked
        ? allAttachmentValues[e.target.value]
        : [],
    });
  };

  return (
    <div className="lists-container">
      <Collapse defaultActiveKey={defaultActiveKey} clickHeaderToExpand={false}>
        {attachmentItems.map((item, outerIndex) => {
          return (
            <Collapse.Panel
              header={
                <Checkbox
                  value={item.name}
                  key={outerIndex}
                  checked={
                    checkedValues[item.name] &&
                    checkedValues[item.name].length !== 0 &&
                    checkedValues[item.name].length ===
                      allAttachmentValues[item.name].length
                  }
                  onChange={handleCheckBoxChange}>
                  {item.name}
                </Checkbox>
              }
              itemKey={outerIndex.toString()}
              key={outerIndex.toString()}>
              <CheckboxGroup
                options={item.attachment}
                value={(checkedValues && checkedValues[item.name]) || []}
                onChange={(val) =>
                  handleCheckboxGroupChange(val, item.name)
                }></CheckboxGroup>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
