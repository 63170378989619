import { CurTurn } from "../../../models/turn";
import request from "../../request";

export const getTurnInfoPath = "/turn";

/**
 * 获取某组所有轮次信息, 不传 groupID 默认为用户所在组
 * @returns
 */
export async function getTurnInfoReq(groupID?: number, period?: number) {
  if (groupID === undefined) return null;
  const res = await request<CurTurn[]>({
    url: `${getTurnInfoPath}`,
    method: "GET",
    params: {
      group: groupID,
      period,
    },
  });

  return res;
}
