import { PeriodInfo } from "../../../models/period";
import request from "../../request";

/**
 * 获得当前周期各个组的招新信息
 * @returns
 */
export async function getPeriodDetailReq(
  period_id: number | undefined
): Promise<PeriodInfo | null> {
  if (period_id === undefined) return null;
  const res = await request<PeriodInfo>({
    url: `/association/period/${period_id}/detail`,
    method: "GET",
  });

  return res;
}
