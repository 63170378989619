import useSWR from "swr";

import { getSelfPath, getSelfReq } from "../../http/member/getSelf";

/** 获得登录用户的个人信息 */
export function useSelf(shouldSendReq = true) {
  const { isValidating, mutate, data, error } = useSWR(
    getSelfPath + shouldSendReq,
    () => (shouldSendReq && getSelfReq()) || null
  );

  return {
    self: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
