import "./index.scss";
import { countdownFromNow } from "../../utils/countdownFromNow";

export type UploadStateProps = {
  open: boolean;
  closeTime?: number;
};

export default function UploadState(props: UploadStateProps) {
  const { open, closeTime } = props;
  const { days, hours, minutes } = countdownFromNow((closeTime || 0) * 1000);
  const timeTip =
    days || hours || minutes
      ? `${days ? `${days}天` : ""}${hours ? `${hours}小时` : ""}${
          minutes ? `${minutes}分钟` : ""
        }后自动关`
      : "即将关闭";

  return (
    <div className="upload_state">
      <div>{open ? "附件上传开启中" : "附件上传已关闭"}</div>
      <div className="upload_state-time">{open ? timeTip : ""}</div>
    </div>
  );
}
