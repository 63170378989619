import { ResumeDetail, ResumeStatus } from "../../../models/resume";
import { wait } from "../../../utils/wait";
import request from "../../request";

export type GetResumeDetailReqData = {
  id: number;
};

export const getResumeDetailPath = "/resume";

/**
 * 获得简历详情
 */
export async function getResumeDetailReq({
  id,
}: GetResumeDetailReqData): Promise<ResumeDetail | null> {
  if (isNaN(id)) return null;
  const res = await request<ResumeDetail>({
    url: `/resume/${id}`,
    method: "GET",
  });

  return res;
}
