import { NoticeResult } from "../../../models/notice";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getNoticeListPath = "/notify/table";

/**
 * 获取以往通知列表
 * @returns
 */
export async function getNoticeListReq(): Promise<NoticeResult[] | null> {
  const res = await request<NoticeResult[]>({
    url: getNoticeListPath,
    method: "GET",
  });

  return res;
}
