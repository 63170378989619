import "./App.scss";

import { Route, Routes, useLocation } from "react-router-dom";
import TheHeader from "./components/TheHeader";
import { TOKEN_KEY } from "./constants/token";
import { useWatermark } from "./hooks/useWatermark";
import { routesObj } from "./routes";
import ProtectedRoute from "./routes/ProtectedRoute";

function App() {
  const location = useLocation();
  useWatermark();

  // 获取 token
  const token = localStorage.getItem(TOKEN_KEY);

  return (
    <div>
      {/* 只有在非 /login 且有 token 才显示 TheHeader */}
      {location.pathname === "/login" || !token || <TheHeader></TheHeader>}
      <div className="limit-height">
        <Routes>
          {routesObj.map((o) => {
            if (o.protected) {
              return (
                <Route
                  key={o.path}
                  {...o}
                  element={<ProtectedRoute>{o.element}</ProtectedRoute>}
                />
              );
            } else {
              return <Route {...o} key={o.path} />;
            }
          })}
        </Routes>
      </div>
    </div>
  );
}

export default App;
