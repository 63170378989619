import { UserManagerSettings } from "oidc-client-ts";

// 后面可以用环境变量而不是直接修改在这里

// OIDC client configuration
export const oidcConfig: UserManagerSettings = {
  authority: "https://api.bingyan.net/sso/oidc",
  client_id: process.env.REACT_APP_CLIENT_ID || "NULL_CLIENT_ID", //da3e7458-43d5-47ac-a7a9-499aa3b82c9c
  redirect_uri: process.env.REACT_APP_REDIRECT_URI || "NULL_REDIRECT_URI", //"https://cvs-dev.hust.online/resume/callback",
  response_type: "code",
  scope: "openid profile phone email",
};
