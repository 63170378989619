import { useMemo, useState } from "react";

import { useGroupList } from "../../../api/swr/group/useGroupList";
import { useTemplates } from "../../../api/swr/template/useTemplates";

export function useTemplateOfGroup() {
  const { templates } = useTemplates();
  const { groupList } = useGroupList();

  const [curGroupName, setCurGroupName] = useState<string>("全部");

  const filteredTemplates = useMemo(
    () =>
      templates
        ?.filter((t) =>
          curGroupName === "全部" ? true : t.group_name === curGroupName
        )
        .reverse(),
    [curGroupName, templates]
  );

  return {
    groupList,
    curGroupName,
    filteredTemplates,
    setCurGroupName,
  };
}
