import "./index.scss";

import { Skeleton } from "@douyinfe/semi-ui";

import Button from "../../../../components/Button";
import { Period } from "../../../../models/period";
import { useJumpTo } from "../../../../hooks/useJumpTo";

export type OverviewTotalProps = {
  periodInfo?: Period | null;
};

export default function OverviewTotal(props: OverviewTotalProps) {
  const { periodInfo } = props;
  const jumpTo = useJumpTo();

  if (!periodInfo) {
    return (
      <Skeleton
        active
        placeholder={<Skeleton.Paragraph rows={3} />}
        className="overview_total"
        style={{ display: "block" }}
      />
    );
  }

  return (
    <div className="overview_total">
      <div></div>
      <div className="overview_total-count">本轮总人数</div>
      <div className="overview_total-count">报名总人数</div>
      <div></div>
      <div className="overview_total-title ellipsis">{periodInfo.name}</div>
      <div className="overview_total-title">{periodInfo.now_count}</div>
      <div className="overview_total-title">{periodInfo.signup_count}</div>
      {!periodInfo.current && (
        <div>
          <Button content="查看简历" type="filled" onClick={ () => { jumpTo("/resume", { periodID: periodInfo.id }) } } />
        </div>
      )}
    </div>
  );
}
