import { TimeFrame } from "../../../models/schedule";
import { QuestionnaireResultProps } from "../components/QuestionnaireResult";

export const resultTransformer = (results: TimeFrame[], duration: number) => {
  const newResults = results.map((result) => {
    const resumes = (result.resumes || []).map((resume) => ({
      ...resume,
      resume_id: resume.resume_id.toString(),
    }));
    return {
      ...result,
      timeframe_id: result.timeframe_id.toString(),
      resumes,
      duration,
    };
  });

  return newResults;
};
