import "./index.scss";

export type TurnStateProps = {
  title: string;
  time: number;
  passed: number;
  eliminated: number;
  unnoticed: number;
  id: number;
};

export default function TurnState(props: TurnStateProps) {
  const { eliminated, passed, time, title, unnoticed } = props;

  return (
    <div className="turn_state">
      <div className="turn_state-info">
        <div>{title}</div>
        {/* TODO 时间 */}
        {/* <div className="turn_state-info-time">2021/3/21 23:00</div> */}
      </div>
      <div className="turn_state-count">
        <div className="turn_state-count-number">{passed}</div>
        <div className="turn_state-count-number">{eliminated}</div>
        <div className="turn_state-count-number">{unnoticed}</div>
        <div className="turn_state-count-text">通过</div>
        <div className="turn_state-count-text">淘汰</div>
        <div className="turn_state-count-text">未通知</div>
      </div>
    </div>
  );
}
