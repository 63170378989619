import "./index.scss";

import { FC, useRef } from "react";
import { useSWRConfig } from "swr";

import {
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  TabPane,
  Tabs,
  Tag,
  TagGroup,
  Tooltip,
} from "@douyinfe/semi-ui";
import { TagColor, TagProps } from "@douyinfe/semi-ui/lib/es/tag";

import { addTagReq } from "../../../api/http/resume/addTag";
import { deleteTagReq } from "../../../api/http/resume/deleteTag";
import { useCurResumeDetail } from "../../../api/swr/resume/useResumeDetail";
import { useTagsOfThisGroup } from "../../../hooks/useTagsOfThisGroup";
import { showToast } from "../../../utils/showToast";
import ApplicantInfo from "./components/ApplicantInfo";
import ResumeAnswerTab from "./components/ResumeAnswerTab";
import ResumeCommentTab from "./components/ResumeCommentTab";
import { TabKey, useCurTab } from "./hooks/useCurTab";

import type { Tag as TagType } from "../../../models/resume";
type ResumeMainProps = {};

const ResumeMain: FC<ResumeMainProps> = () => {
  const resume = useCurResumeDetail();

  const { mainTab, setActiveKey, tabKeys } = useCurTab();
  const { mutate } = useSWRConfig();
  const tags = useTagsOfThisGroup();

  const inputRef = useRef<HTMLInputElement>(null);
  async function submitTag() {
    const tag = inputRef.current?.value;
    if (!resume || !tag) return;

    const success = await addTagReq({ id: resume.id, tag });
    if (!success) return;

    mutate(`/resume/${resume.id}`);
    showToast("添加成功! ", "success");
  }

  async function deleteTag(tagID: number) {
    if (!resume) return;
    const success = await deleteTagReq({ resumeID: resume.id, tagID });
    if (!success) return;

    mutate(`/resume/${resume.id}`);
    showToast("删除成功! ", "success");
  }

  const tagGroupData: TagProps[] = tags.map((t) => ({
    color: "blue",
    onClick: () => inputRef.current && (inputRef.current.value = t),
    children: t,
  }));

  function addTab() {
    Modal.confirm({
      title: "请输入标签",
      content: (
        <div>
          <Input
            // 用非受控组件是因为 semi design 把这个组件提升到了顶层, 在这里 setState 不会更新输入框数据
            ref={inputRef}
            style={{ marginTop: 12 }}
            placeholder="标签不能超过 7 个字"
            maxLength={7}
          ></Input>
          {tags.length > 0 && (
            <TagGroup
              style={{ marginTop: "1rem" }}
              maxTagCount={5}
              tagList={tagGroupData}
            />
          )}
        </div>
      ),
      onOk: submitTag,
      icon: null,
    });
  }

  function RenderTag(tag: TagType) {
    return {
      color: "blue",
      children: (
        <Popconfirm
          title="确定删除此标签？"
          content="此修改将不可逆"
          onConfirm={() => deleteTag(tag.id)}
          trigger="click"
        >
          {tag.tag}
        </Popconfirm>
      ),
      key: tag.id,
    };
  }

  return (
    <div className="resume-main">
      <ApplicantInfo resume={resume}></ApplicantInfo>

      {resume ? (
        <TagGroup
          maxTagCount={5}
          className="resume-main-tags"
          tagList={
            [
              ...resume.tags.map(RenderTag),
              {
                color: "blue",
                children: <Tooltip content="添加标签">+ 标签</Tooltip>,
                onClick: addTab,
              },
            ] as TagProps[]
          }
          size="large"
          showPopover
        />
      ) : (
        <Skeleton
          active
          placeholder={<Skeleton.Title className="resume-main-tags" />}
        ></Skeleton>
      )}

      <Tabs
        type="line"
        activeKey={mainTab}
        onChange={(k) => setActiveKey(k as TabKey)}
      >
        <TabPane tab={tabKeys[0]} itemKey={tabKeys[0]}>
          <ResumeAnswerTab answers={resume && resume.custom}></ResumeAnswerTab>
        </TabPane>
        <TabPane tab={tabKeys[1]} itemKey={tabKeys[1]}>
          <ResumeCommentTab
            comments={resume && resume.comments}
          ></ResumeCommentTab>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ResumeMain;
