import useSWR from "swr";

import { useQuery } from "../../../hooks/useQuery";
import { ResumeDetail } from "../../../models/resume";
import { getResumeDetailReq } from "../../http/resume/getResumeDetail";

export function useResumeDetail(id: number) {
  const { isValidating, mutate, data, error } = useSWR(
    `/resume/${id}`,
    () => getResumeDetailReq({ id }),
    {
      refreshInterval: 3000,
      revalidateIfStale: false,
    }
  );

  return {
    resume: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}

/**
 * ## 根据当前路由 query 中的 resumeID 字段自动获得当前简历详情
 * **多次调用只会发一次请求, 所以在任何需要简历详情的地方都可以放心大胆的直接用**
 * @returns
 * - `null`: 请求出错或路由找不到 id 的参数
 * - `undefined`: 请求中
 * - `ResumeDetail`: 请求到的结果
 */
export function useCurResumeDetail(): null | ResumeDetail | undefined {
  const { resumeID: resumeIdStr } = useQuery();
  const resumeID = parseInt(resumeIdStr || "");

  const { resume, error, loading } = useResumeDetail(resumeID);

  if (error) return null;
  if (loading) return undefined;

  return resume;
}
