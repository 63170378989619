import "./index.scss";

import { FC, useState } from "react";
import { useSWRConfig } from "swr";

import {
  IconChevronDown,
  IconChevronUp,
  IconDeleteStroked,
} from "@douyinfe/semi-icons";
import { Popconfirm } from "@douyinfe/semi-ui";

import { deleteCommentReq } from "../../../../../../../../../api/http/resume/deleteComment";
import { useSelf } from "../../../../../../../../../api/swr/member/useSelf";
import { useCurResumeDetail } from "../../../../../../../../../api/swr/resume/useResumeDetail";
import { Comment } from "../../../../../../../../../models/comment";
import { showToast } from "../../../../../../../../../utils/showToast";

type CommentItemProps = {
  comment: Comment;
};

const CommentItem: FC<CommentItemProps> = ({ comment }) => {
  const [showMore, setShowMore] = useState(false);
  const { mutate } = useSWRConfig();
  const resume = useCurResumeDetail();
  const self = useSelf();

  async function deleteComment(comment: Comment) {
    if (!resume) return;

    if (comment.user_id !== self.self?.id) {
      return showToast("无法删除他人的评论", "warning");
    }

    const success = await deleteCommentReq({ commentID: comment.id });

    if (!success) return;

    mutate(`/resume/${resume.id}`);
    showToast("删除成功!", "success");
  }

  return (
    <div className="r-rnc-comment_item">
      <div className="r-rnc-comment_item-header">
        <div className="r-rnc-comment_item-header-name">{comment.nickname}</div>
        <Popconfirm
          style={{ width: "23rem" }}
          title="确定删除此评论吗"
          onConfirm={() => deleteComment(comment)}
        >
          <IconDeleteStroked
            size="large"
            style={{ opacity: 0.4, cursor: "pointer" }}
          />
        </Popconfirm>
      </div>
      <div className={`r-rnc-comment_item-content ${showMore ? "more" : ""}`}>
        {comment.content}
      </div>

      {showMore ? (
        <IconChevronUp
          onClick={() => setShowMore(false)}
          className="r-rnc-show_more"
        />
      ) : (
        <IconChevronDown
          onClick={() => setShowMore(true)}
          className="r-rnc-show_more"
        />
      )}
    </div>
  );
};

export default CommentItem;
