import "./index.scss";

import { FC, useState } from "react";
import { mutate, useSWRConfig } from "swr";

import { IllustrationNoContent } from "@douyinfe/semi-illustrations";
import { Button, Empty, Input, Skeleton, TextArea } from "@douyinfe/semi-ui";

import { makeCommentReq } from "../../../../../../../api/http/resume/makeComment";
import { useCurResumeDetail } from "../../../../../../../api/swr/resume/useResumeDetail";
import { showToast } from "../../../../../../../utils/showToast";
import CommentItem from "./components/CommentItem";

type CommentsProps = {};

const Comments: FC<CommentsProps> = () => {
  const [comment, setComment] = useState("");
  const resume = useCurResumeDetail();
  const { mutate } = useSWRConfig();

  if (!resume)
    return (
      <Skeleton
        active
        placeholder={
          <div className="r-rnc-comments">
            <h4>本轮评价</h4>
            <Skeleton.Title></Skeleton.Title>
            {Array.from({ length: 3 }).map((_, i) => (
              <div className="r-rnc-comment_item" key={i}>
                <Skeleton.Title
                  style={{ width: "30%", marginBottom: "0.5rem" }}
                />
                <Skeleton.Paragraph rows={3} />
              </div>
            ))}
          </div>
        }
      />
    );

  async function makeComment() {
    if (!resume || !comment) return;
    const success = await makeCommentReq({
      content: comment,
      resumeID: resume.id,
    });

    mutate(`/resume/${resume.id}`);
    setComment("");
    if (success) showToast("评论成功", "success");
  }

  const thisPeriod = resume.comments[resume.comments.length - 1];

  return (
    <div className="r-rnc-comments">
      <h4>本轮评价</h4>
      <div className="r-rnc-comments-text">
        <TextArea
          /* style={{ padding: ".5rem 0.5rem" }} */
          /* suffix={
          <Button onClick={makeComment} theme="solid" type="primary">
            发送
          </Button>
        } */
          value={comment}
          autosize
          rows={1}
          onChange={(s) => setComment(s)}
          /* onEnterPress={makeComment} */
          showClear
        ></TextArea>
        <Button onClick={makeComment} theme="solid" type="primary">
          发送
        </Button>
      </div>

      {thisPeriod?.comments?.length ? (
        thisPeriod.comments.map((c) => (
          <CommentItem comment={c} key={c.id}></CommentItem>
        ))
      ) : (
        <Empty
          style={{ margin: "1rem" }}
          image={<IllustrationNoContent />}
          description="没有评论呢, 发表一条吧!"
        ></Empty>
      )}
    </div>
  );
};

export default Comments;
