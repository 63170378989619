import "./index.scss";

import Button from "../../../../components/Button";
import { useEffect, useState } from "react";
import { deleteScheduleReq } from "../../../../api/http/schedule/deleteSchedule";
import { showToast } from "../../../../utils/showToast";
import { mutate } from "swr";
import { getScheduleDetailPath } from "../../../../api/http/schedule/getScheduleDetail";
import { getSchedulesOfMyGroupPath } from "../../../../api/http/schedule/getSchedulesOfMyGroup";
import { getCurTurnSchedulesPath } from "../../../../api/http/schedule/getCurTurnSchedules";
import { ScheduleTimeArrangeItem } from "../../../../models/schedule";
import { changeScheduleTimeArrangeReq } from "../../../../api/http/schedule/changeScheduleTimeArrange";
import { resetScheduleReq } from "../../../../api/http/schedule/resetSchedule";
import { useNavigate } from "react-router-dom";
import { Empty } from "@douyinfe/semi-ui";
import {
  IllustrationConstruction,
  IllustrationConstructionDark,
} from "@douyinfe/semi-illustrations";

type ResultType = {
  timeframe_id: string;
  start_time: number;
  duration: number;
  resumes: { name: string; resume_id: string }[];
  capacity: number;
};

export type QuestionnaireResultProps = {
  results: ResultType[];
  notArrange: { name: string; resume_id: string }[];
  schedule_id: number;
  isOpen: boolean;
};

export default function QuestionnaireResult(props: QuestionnaireResultProps) {
  const noTimeRes = {
    resumes: props.notArrange,
    capacity: 0,
    start_time: -1,
    duration: 0,
    timeframe_id: "no_time",
  };

  const nav = useNavigate();
  const res = [...props.results];
  props.notArrange.length && res.push(noTimeRes);

  // const [allResults, setAllResults] = useState<ResultType[]>([...res]);

  const [overItem, setOverItem] = useState<string | null>(null);
  const [dragItem, setDragItem] = useState<{
    name: string;
    resume_id: string;
  } | null>(null);

  /*   const deleteResume = (resume_id: string) => {
    setAllResults((allResults) => {
      allResults.forEach((timeframe) => {
        let flag = -1;
        timeframe.resumes.forEach((resume, index) => {
          if (resume.resume_id === resume_id) flag = index;
        });
        if (flag !== -1) timeframe.resumes.splice(flag, 1);
      });
      return allResults;
    });
  };

  const addResume = () => {
    setAllResults((allResults) => {
      allResults.forEach((timeframe) => {
        if (timeframe.timeframe_id === overItem && dragItem)
          timeframe.resumes.push(dragItem);
      });
      return allResults;
    });
  }; 

  const handleChange = async () => {
    //
    const data: ScheduleTimeArrangeItem[] = [];
    allResults.forEach((timeframe) => {
      let dataTimeframe: ScheduleTimeArrangeItem;
      if (timeframe.timeframe_id === "no_time") {
        dataTimeframe = { not_arrange: true, resumes: [] };
      } else {
        dataTimeframe = {
          timeframe_id: parseInt(timeframe.timeframe_id),
          resumes: [],
        };
      }
      timeframe.resumes.forEach((resume) => {
        dataTimeframe.resumes.push(parseInt(resume.resume_id));
      });
      data.push(dataTimeframe);
    });
    const success = await changeScheduleTimeArrangeReq({
      timeArrange: data,
      schedule_id: props.schedule_id,
    });
    if (success) {
      showToast("修改成功", "success");
      mutate(getCurTurnSchedulesPath);
      mutate(getSchedulesOfMyGroupPath);
      mutate(`${getScheduleDetailPath}/${props.schedule_id}`);
    }
  };

  const handleReset = async () => {
    if (props.schedule_id === -1) return;
    const success = await resetScheduleReq({ schedule_id: props.schedule_id });
    if (success) {
      showToast("重置成功", "success");
      mutate(getCurTurnSchedulesPath);
      mutate(getSchedulesOfMyGroupPath);
      mutate(`${getScheduleDetailPath}/${props.schedule_id}`);
      nav(0);
    }
  };*/
  const handleDelete = async () => {
    if (props.schedule_id === -1) return;
    const success = await deleteScheduleReq({ schedule_id: props.schedule_id });
    if (success) {
      showToast("删除成功", "success");
      mutate(getCurTurnSchedulesPath);
      mutate(getSchedulesOfMyGroupPath);
    }
  };

  return (
    <div className="questionnaire_result">
      {props.isOpen ? (
        <div className="questionnaire_result-actions">
          {/* <Button type="filled" onClick={handleChange}>
          保存时间安排修改
        </Button>
        <Button onClick={handleReset}>重置填报状态</Button> */}
          <Button onClick={handleDelete}>删除当前问卷</Button>
        </div>
      ) : null}

      {!res.length ? (
        <Empty
          image={
            <IllustrationConstruction style={{ width: 150, height: 150 }} />
          }
          darkModeImage={
            <IllustrationConstructionDark style={{ width: 150, height: 150 }} />
          }
          title={"暂无数据"}
          description="当前无问卷或未设置问卷时间段"
        />
      ) : (
        <>
          <div className="questionnaire_result-header">
            <div className="questionnaire_result-header-time">时间安排</div>
            <div className="questionnaire_result-header-interviewers">
              人员安排
            </div>
            <div className="questionnaire_result-header-capacity">计划容量</div>
          </div>
          {res
            .sort((f1, f2) =>
              f1.start_time != -1 ? f1.start_time - f2.start_time : 1
            )
            .map((result, index) => {
              const startTime = new Date(result.start_time * 1000);
              const endTime = new Date(
                (result.start_time + result.duration) * 1000
              );
              return (
                <div
                  className="questionnaire_result-content"
                  key={result.timeframe_id}
                >
                  <div
                    className={`questionnaire_result-content-time ${
                      overItem === result.timeframe_id ? "highlight" : ""
                    }`}
                  >
                    {result.timeframe_id === "no_time" ? (
                      <div className="questionnaire_result-content-no_time">
                        没时间
                      </div>
                    ) : (
                      <>
                        <div className="questionnaire_result-content-index">
                          {index + 1}
                        </div>
                        {startTime.toLocaleString().slice(0, -3)} -{" "}
                        {endTime.toLocaleTimeString().slice(0, -3)}
                      </>
                    )}
                  </div>
                  <div
                    className={`questionnaire_result-content-interviewers ${
                      overItem === result.timeframe_id ? "highlight" : ""
                    }`}
                    /* onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDragEnter={(e) => {
                      setOverItem(() => result.timeframe_id);
                      console.log("dragenter", result.timeframe_id);
                    }}
                    onDragLeave={(e) => {
                      setOverItem(() => null);
                      console.log("dragleave", result.timeframe_id);
                    }} */
                  >
                    {result.resumes.map((interviewer) => (
                      <div
                        key={interviewer.resume_id}
                        /* draggable
                        onDragStart={(e) => {
                          setDragItem(() => interviewer);
                          console.log("dragstart", interviewer.name);
                        }}
                        onDragEnd={(e) => {
                          if (!overItem) {
                            setDragItem(() => null);
                            return;
                          }
                          deleteResume(interviewer.resume_id);
                          addResume();
                          setOverItem(() => null);
                          setDragItem(() => null);
                          console.log("dragend", interviewer.name);
                        }} */
                      >
                        <Button
                          content={interviewer.name}
                          type="light_filled"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="questionnaire_result-content-capacity">
                    {result.timeframe_id === "no_time" ? null : result.capacity}
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
}
