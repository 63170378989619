import "./index.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

import { DatePicker, Input, Modal, Radio, RadioGroup } from "@douyinfe/semi-ui";

import { createNewTurnReq } from "../../../../api/http/turn/createNewTurn";
import { getCurTurnInfoPath } from "../../../../api/http/turn/getCurTurnInfo";
import { useSelf } from "../../../../api/swr/member/useSelf";
import { getDisabledDate, getDisableTime } from "../../../../utils/getDisabledDateTime";
import { showToast } from "../../../../utils/showToast";

export type DialogNextTurnProps = {
  setShowNextTurnDialog: (showNextTurnDialog: boolean) => void;
  showNextTurnDialog: boolean;
};

export default function DialogNextTurn(props: DialogNextTurnProps) {
  const { setShowNextTurnDialog, showNextTurnDialog } = props;
  const navigate = useNavigate();

  const self = useSelf();
  const { mutate } = useSWRConfig();

  const [turnName, setTurnName] = useState("");
  const [deadline, setDeadline] = useState(Date.now() + 1000 * 3661);
  const [canUploadFile, setCanUploadFile] = useState<0 | 1>();

  const createNextTurn = async () => {
    if (!turnName) {
      showToast("轮次名称不得为空", "error");
      return;
    }
    if (canUploadFile === undefined) {
      showToast("需设置下一轮能否上传附件", "error");
      return;
    }
    if (deadline < Date.now())
      return showToast("下一轮上传附件截止时间过近，请重新设置", "error");

    const res = await createNewTurnReq(turnName, canUploadFile && deadline);
    if (!res) return;

    setTurnName("");
    showToast("创建成功", "success");
    setShowNextTurnDialog(false);
    navigate("/resume");

    mutate(`${getCurTurnInfoPath}?group=${self?.self?.group_id}`);
  };

  return (
    <Modal
      title="进入下一轮"
      visible={showNextTurnDialog}
      onCancel={() => {
        setShowNextTurnDialog(false);
        setTurnName("");
        setCanUploadFile(undefined);
      }}
      centered
      className="dialog_next_turn"
      onOk={createNextTurn}
    >
      请输入下一轮名称（如：第2轮/面试）
      <Input
        autoFocus
        className="dialog_next_turn-settings"
        placeholder="在此填写轮次名称"
        value={turnName}
        onChange={(value) => setTurnName(value)}
      ></Input>
      下一轮能否上传附件（如笔试答卷）：
      <RadioGroup
        value={canUploadFile}
        onChange={(e) => setCanUploadFile(e.target.value)}
      >
        <Radio value={1}>是</Radio>
        <Radio value={0}>否</Radio>
      </RadioGroup>
      {!!canUploadFile && (
        <div className="dialog_next_turn-settings">
          请设置上传附件的截止时间：
          <DatePicker
            type="dateTime"
            value={deadline}
            onChange={(date) => setDeadline(new Date(date as Date).getTime())}
            disabledDate={getDisabledDate(new Date())}
            disabledTime={getDisableTime(new Date())}
          />
        </div>
      )}
    </Modal>
  );
}
