import "./index.scss";

import Button from "../../../../components/Button";
import { useJumpTo } from "../../../../hooks/useJumpTo";

export type UnconfirmedResumeProps = {
  name: string;
  major: string;
  id: number;
};

export default function UnconfirmedResume(props: UnconfirmedResumeProps) {
  const { major, id, name } = props;

  const jumpTo = useJumpTo();
  // http://localhost:3000/resume/resume?periodID=6&groupID=17&sort=%E6%8A%A5%E5%90%8D%E6%97%B6%E9%97%B4&mainTab=%E6%8A%A5%E5%90%8D%E8%A1%A8&actionTab=%E5%BF%AB%E9%80%9F%E8%AF%84%E4%BB%B7&resumeID=105
  return (
    <div className="unconfirmed_resume">
      <div>{name}</div>
      <div>{major}</div>
      <Button
        content="去评价"
        onClick={() => jumpTo("/resume", { resumeID: id })}
      />
    </div>
  );
}
