import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelf } from "../api/swr/member/useSelf";
import { TOKEN_KEY } from "../constants/token";
import { setWatermark } from "../utils/setWatermark";

export function useWatermark() {
  const location = useLocation();
  const token = localStorage.getItem(TOKEN_KEY);
  const { self } = useSelf(location.pathname !== "/login" && !!token);

  useEffect(() => {
    if (!self) return;
    setWatermark({ fillText: self.nickname });
  }, [self?.id]);
}
