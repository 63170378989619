import { useNavigate } from "react-router-dom";

import { obj2QueryStr } from "../utils/obj2QueryStr";
import { Scheduler } from "../utils/scheduler";
import { getQueryParams, QueryKeys } from "./useQuery";

const navScheduler = new Scheduler(1);

/**
 * 对 useNavigate 进行的封装, 使之更方便处理奇妙的 query
 * @returns jumpTo 路由跳转函数
 *
 * ### 用法
 *
 * ```tsx
 * const jumpTo = useJumpTo()
 * // 假如你在 `/home?baz=42` 界面, 将跳转到 `/home?baz=42&foo=bar` 界面
 * <div onClick={()=>jumpTo("/home", {foo: "bar"})}>JUMP!</div>
 * ```
 *
 * ### 注意
 *
 * - 代码中有些地方处理跳转的时候，还是使用的 useNavigate(), 如果没有特殊需求，建议使用 useJumpTo()
 */
export function useJumpTo() {
  const navigate = useNavigate();

  /**
   * @param path 要跳转的路径(同 navigate 函数, 不带 query)
   * @param queryObj 需携带的 query 对象
   * - 若第三个参数为 true 则清空query, 跳到新传入的 query 中
   * - 若第三个参数为 falsy 则不改动其他现有 query, 只将传入的 query 进行更新
   * @param force 是否清空 query 并更新
   * @param replace 是否不使用 push 跳转而是 replace 跳转
   */
  return function jumpTo(
    path: string,
    queryObj: { [K in QueryKeys]?: string | number },
    force?: boolean,
    replace?: boolean
  ) {
    navScheduler.add(() => {
      const oldQuery = getQueryParams(window.location.href.split("?")[1]);
      const queryStr = obj2QueryStr(
        // 如果强制跳转则完全使用新 query, 否则将新旧 query 合并
        force ? queryObj : { ...oldQuery, ...queryObj }
      );

      navigate(`${path}${queryStr}`, { replace });
      // console.log("jump to:", `${path}${queryStr}`);
    });
  };
}
