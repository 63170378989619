import "./index.scss";

import { FC } from "react";
import { useSWRConfig } from "swr";

import { Skeleton } from "@douyinfe/semi-ui";

import { setPeriodRateReq } from "../../../../../../../api/http/resume/setPeriodRate";
import { useCurResumeDetail } from "../../../../../../../api/swr/resume/useResumeDetail";
import RateResultBar from "../../../../../../../components/RateResultBar";
import { Attitude } from "../../../../../../../models/resume";
import Star1 from "./imgs/1.svg";
import Star2 from "./imgs/2.svg";
import Star3 from "./imgs/3.svg";
import Star4 from "./imgs/4.svg";
import Star5 from "./imgs/5.svg";

const stars = [Star1, Star2, Star3, Star4, Star5];

type RateProps = {};

const Placeholder = () => (
  <Skeleton
    active
    placeholder={
      <div className="r-rnc-rate">
        <Skeleton.Title className="r-rnc-rate-action"></Skeleton.Title>
        <Skeleton.Title></Skeleton.Title>
      </div>
    }
  />
);

const Rate: FC<RateProps> = () => {
  const resume = useCurResumeDetail();
  const { mutate } = useSWRConfig();

  if (!resume) return <Placeholder></Placeholder>;

  const result = new Array(5).fill(0) as [
    number,
    number,
    number,
    number,
    number
  ];

  const commentOfThisRound = resume.comments[resume.comments.length - 1];
  commentOfThisRound &&
    commentOfThisRound.attitudes.forEach((a) => (result[a.score - 1] += 1));

  async function rate(attitude: Attitude) {
    if (!resume) return;
    const success = await setPeriodRateReq({ attitude, resumeID: resume.id });
    success && mutate(`/resume/${resume.id}`);
  }

  return (
    <div className="r-rnc-rate">
      <div className="r-rnc-rate-action">
        <span className="r-rnc-key">本轮态度</span>
        <div className="r-rnc-rate-action-item-wrap">
          {stars.map((s, i) => (
            <img
              src={s}
              alt={`${1 + i} Star`}
              className={`r-rnc-rate-action-item ${
                resume.attitude_by_me === i + 1 ? "active" : ""
              }`}
              key={i}
              onClick={() => rate(i + 1)}
            />
          ))}
        </div>
      </div>
      <RateResultBar
        result={result.every((r) => r === 0) ? undefined : result}
      ></RateResultBar>
    </div>
  );
};

export default Rate;
