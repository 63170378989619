import request from "../../request";

export const getAllowOfRecruitPath = "/group/signup";

type State = {
  allow_post: boolean;
};

/**
 * 获得报名表开启关闭
 */
export async function getAllowOfRecruitReq(): Promise<State | null> {
  const res = await request<State>({
    url: getAllowOfRecruitPath,
    method: "GET",
  });

  return res;
}
