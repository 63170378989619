import useSWR from "swr";

import {
    GetCurInterviewersParams, getCurInterviewersPath, getCurInterviewersReq
} from "../../http/notify/getCurInterviewers";

/** 获得通知界面被统治者的信息 */
export function useCurInterviewers(params: GetCurInterviewersParams) {
  const { turnID, notifyType } = params;

  const { isValidating, mutate, data, error } = useSWR(
    `${getCurInterviewersPath}/${turnID}?notify_type=${notifyType}`,
    () => getCurInterviewersReq(params)
  );

  return {
    curTurnInterviewers: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
