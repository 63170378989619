import { ScheduleEdit } from "../../../models/schedule";
import request from "../../request";

export type CloseScheduleReqData = Pick<ScheduleEdit, "schedule_id">;

export const closeSchedulePath = "/schedule";

/**
 * 关闭一份问卷模板
 * @returns
 */
export async function closeScheduleReq(data: CloseScheduleReqData) {
  const res = await request<string>({
    url: `${closeSchedulePath}/${data.schedule_id}?open=false`,
    method: "PUT",
  });

  return res !== null;
}
