/**
 *将给定数据导出成 csv 文件
 * @param rows 要下载的表格数据, 是个二维数组
 * @param fileName 下载的文件名
 *
 * e.g.
 *
 * ```ts
 * downloadCSV(
 *  selected.map((s) => [s.name, s.phone]),
 *  "人员信息"
 * );
 * ```
 */
export function downloadCSV(
  rows: (string | number)[][],
  fileName = "download"
) {
  const csvContent =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    fileName.endsWith(".csv") ? fileName : `${fileName}.csv`
  );
  document.body.appendChild(link); // Required for FF

  link.click();

  link.remove();
}
