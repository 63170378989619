import "./index.scss";

import { FC } from "react";

type RateResultBarProps = {
  /** 投各个分数的票数(分别代表投 0, 1, ..., 4 分的人数), undefined 则显示灰色分数条 */
  result?: [number, number, number, number, number];
  /** 所有人的态度(分别代表投了多少分), 会被计算成上述 result */
  attitudes?: number[];
};

const RateResultBar: FC<RateResultBarProps> = ({ result, attitudes }) => {
  if (!result && !attitudes) {
    return (
      <div
        className="c-rate_result_bar"
        style={{ filter: result ? undefined : "grayscale(1)" }}
      >
        {[0, 0, 0, 0, 0].map((r, index) => (
          <div
            key={index}
            className="c-rate_result_bar-item"
            style={{ flex: 1 }}
          >
            <div className="c-rate_result_bar-item-num">{r}</div>
          </div>
        ))}
      </div>
    );
  }
  result = result ||
    attitudes?.reduce((res, score) => {
      res[score - 1] += 1;
      return res;
    }, new Array(5).fill(0) as [number, number, number, number, number]) || [
      0, 0, 0, 0, 0,
    ];

  return (
    <div
      className="c-rate_result_bar"
      style={{ filter: result ? undefined : "grayscale(1)" }}
    >
      {result.map((r, index) => (
        <div key={index} className="c-rate_result_bar-item" style={{ flex: r }}>
          <div className="c-rate_result_bar-item-num">{r}</div>
        </div>
      ))}
    </div>
  );
};

export default RateResultBar;
