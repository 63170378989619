import { NextTurnInfo } from "../../../models/turn";
import request from "../../request";

export const createNewTurnPath = "/turn";

/**
 * 创建新轮次
 * @param name 下一轮名称
 * @param end_time 下一轮截止时间
 * @returns
 */
export async function createNewTurnReq(
  name: string,
  end_time: number
): Promise<NextTurnInfo | null> {
  const res = await request<NextTurnInfo | null>({
    url: `${createNewTurnPath}`,
    method: "POST",
    data: { name, end_time: Math.round(end_time / 1000) },
  });

  return res;
}
