import { CurTurn } from "../../../models/turn";
import request from "../../request";

export const getCurTurnInfoPath = "/turn/current";

/**
 * 获取某组当前轮次信息，不传groupID默认为用户所在组
 * @returns
 */
export async function getCurTurnInfoReq(
  groupID?: number
): Promise<CurTurn | null> {
  if (groupID === undefined) return null;

  const res = await request<CurTurn>({
    url: `${getCurTurnInfoPath}`,
    method: "GET",
    params: {
      group: groupID,
    },
  });

  return res;
}
