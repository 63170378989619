import useSWR from "swr";
import { getTurnInfoPath, getTurnInfoReq } from "../../http/turn/getTurnInfo";

/**
 * ### 用途
 *
 * - 根据 groupID 获取当前周期所有轮次信息
 */
export function useTurnInfo(groupID?: number, period?: number) {
  // 如果没传 groupID, period, 则 key 对应位置的值为 undefined
  const key = `${getTurnInfoPath}?group=${groupID}&period=${period}`;

  const { data, error, isValidating, mutate } = useSWR(key, () =>
    getTurnInfoReq(groupID, period)
  );

  return {
    turnInfo: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
