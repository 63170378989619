import { TimeFrameEdit } from "../../../models/schedule";
import request from "../../request";

export type AddTimeFrameEditReqData = Pick<
  TimeFrameEdit,
  "start_time" | "amount"
> & { schedule_id: number };

export const addTimeframePath = "/schedule";

/**
 * 添加面试时间段
 * @returns
 */
export async function addTimeframeReq(data: AddTimeFrameEditReqData) {
  const res = await request<string>({
    url: `${addTimeframePath}/${data.schedule_id}/timeframe`,
    method: "POST",
    data,
  });

  return res !== null;
}
