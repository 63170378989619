import { useMemo, useState } from "react";

import { useCurResumeList } from "../api/swr/resume/useResumeList";

/**
 * 获得本组中所有出现过的 tags
 * 按出现数量排序, 有 memo
 */
export function useTagsOfThisGroup() {
  const resumes = useCurResumeList();

  return useMemo<string[]>(() => {
    if (!resumes?.[0]) return [];

    type TagInfo = {
      name: string;
      count: number;
    };

    const tagsMap: Record<string, number> = {};

    const tagsInfo: TagInfo[] = [];

    resumes.forEach((r) => {
      r.tags.forEach((t) => {
        tagsMap[t.tag] = (tagsMap[t.tag] ?? 0) + 1;
      });
    });

    for (const name in tagsMap) {
      tagsInfo.push({
        count: tagsMap[name],
        name,
      });
    }

    tagsInfo.sort((a, b) => b.count - a.count);

    return tagsInfo.map((i) => i.name);
  }, [resumes?.[0]?.id]);
}
