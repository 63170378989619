import "./index.scss";

import { FC } from "react";

import Comments from "./components/Comments";
import Rate from "./components/Rate";
import Switch from "./components/Switch";

type RateNCommentProps = {};

const RateNComment: FC<RateNCommentProps> = () => {
  return (
    <div className="r-rate_and_comment r-rnc">
      <Switch></Switch>
      <Rate></Rate>

      <Comments></Comments>
    </div>
  );
};

export default RateNComment;
