import request from "../../request";

export type MakeCommentReqData = {
  resumeID: number;
  content: string;
};

/**
 * 添发表一条评论
 */
export async function makeCommentReq({
  content,
  resumeID,
}: MakeCommentReqData) {
  const res = await request<string>({
    url: `/turn/comment/${resumeID}`,
    method: "POST",
    data: { content },
  });

  return res !== null;
}
