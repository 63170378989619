import useSWR from "swr";

import { NoticeResultStatus } from "../../../models/notice";
import { TurnStateProps } from "../../../pages/Notice/components/TurnState/index";
import {
  getNoticeListPath,
  getNoticeListReq,
} from "../../http/notify/getNoticeList";

/** 获得以往通知信息 */
export function useNoticeList() {
  const { isValidating, mutate, data, error } = useSWR(getNoticeListPath, () =>
    getNoticeListReq()
  );

  const noticeList: TurnStateProps[] = data
    ? data.map((res) => {
        const status = res.status as NoticeResultStatus[];
        const passedStatus = status.find((s) => s.notify_type === 1);
        const filledStatus = status.find((s) => s.notify_type === 3);
        const unFilledStatus = status.find((s) => s.notify_type === 4);
        const eliminatedStatus = status.find((s) => s.notify_type === 2);
        const unnoticedStatus = status.find((s) => s.notify_type === 0);

        const passCount =
          (passedStatus ? passedStatus.count : 0) +
          (filledStatus ? filledStatus.count : 0) +
          (unFilledStatus ? unFilledStatus?.count : 0);

        return {
          id: res.id,
          title: res.title,
          time: res.create_time,
          passed: passCount,
          eliminated: eliminatedStatus ? eliminatedStatus.count : 0,
          unnoticed: unnoticedStatus ? unnoticedStatus.count : 0,
        };
      })
    : [];

  return {
    noticeList,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
