import { useEffect, useState } from "react";

import { TOPPED_KEY } from "../../../../../constants/token";

export function useToppedGroups() {
  const [toppedGroups, setToppedGroups] = useState<number[]>([]);

  useEffect(() => {
    try {
      const toppedGroupsStr = localStorage.getItem(TOPPED_KEY) || "[]";
      setToppedGroups(JSON.parse(toppedGroupsStr));
    } catch {
      localStorage.setItem(TOPPED_KEY, "[]");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(TOPPED_KEY, JSON.stringify(toppedGroups));
  }, [toppedGroups]);

  function toggleToppedGroup(id: number) {
    if (toppedGroups.includes(id)) {
      setToppedGroups(toppedGroups.filter((i) => i !== id));
    } else {
      setToppedGroups([...toppedGroups, id]);
    }
  }

  return {
    toggleToppedGroup,
    toppedGroups,
  };
}
