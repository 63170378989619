/**
 * 将对象转化成能直接接在 path 后的 query 字符串
 * ```ts
 * // 栗子👇
 * obj2QueryStr({foo: "bar"}) => "?foo=bar"
 * obj2QueryStr({foo: "bar", baz: 42}) => "?foo=bar&baz=42"
 * ```
 */
export function obj2QueryStr(obj: Record<string, string | number>) {
  let url = "?";
  Object.entries(obj)
    .filter((param) => param[1] !== undefined)
    .forEach((param) => {
      url += `${param[0]}=${param[1]}&`;
    });
  const queryString = url.length > 1 ? url.slice(0, url.length - 1) : "";
  return queryString;
}
