import { ScheduleEdit } from "../../../models/schedule";
import request from "../../request";

export type DeleteScheduleReqData = Pick<ScheduleEdit, "schedule_id">;

export const deleteSchedulePath = "/schedule";

/**
 * 删除一份问卷模板
 * @returns
 */
export async function deleteScheduleReq(data: DeleteScheduleReqData) {
  const res = await request<string>({
    url: `${deleteSchedulePath}/${data.schedule_id}`,
    method: "DELETE",
  });

  return res !== null;
}
