import { NoticeInterviewers, NoticeType } from "../../../models/notice";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getCurInterviewersPath = "/notify/table";

export type GetCurInterviewersParams = {
  turnID?: number;
  notifyType?: NoticeType;
};

/**
 * 获取某轮可通知人员及该轮简要信息
 * @returns
 */
export async function getCurInterviewersReq(
  params: GetCurInterviewersParams
): Promise<NoticeInterviewers | null> {
  const { notifyType, turnID } = params;

  if (turnID === undefined) return null;

  const res = await request<NoticeInterviewers>({
    url: `${getCurInterviewersPath}/${turnID}`,
    method: "GET",
    params: {
      notify_type: notifyType,
    },
  });

  return res;
}
