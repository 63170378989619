import request from "../../request";

export type AddTagReqData = {
  id: number;
  tag: string;
};

/**
 * 为简历添加标签
 * @returns
 */
export async function addTagReq({ id, tag }: AddTagReqData) {
  const res = await request<string>({
    url: `/resume/${id}/tag`,
    method: "POST",
    data: { tag },
  });

  return res !== null;
}
