import "./index.scss";

import { FC } from "react";

import { Collapse, Skeleton } from "@douyinfe/semi-ui";

import { ResumeDetail } from "../../../../../models/resume";
import CommentItem from "./components/CommentItem";

type ResumeCommentTabProps = {
  comments: ResumeDetail["comments"] | null | undefined;
};

const ResumeCommentTab: FC<ResumeCommentTabProps> = ({ comments }) => {
  if (!comments) {
    return (
      <Skeleton
        active
        placeholder={Array.from({ length: 3 }).map((_, i) => (
          <Skeleton.Title key={i} style={{ margin: "20px 0" }} />
        ))}
      ></Skeleton>
    );
  }

  comments = [...comments].reverse();

  return (
    <div className="r-comment_tab">
      <Collapse defaultActiveKey={"0"}>
        {comments.map((c, i) => (
          <Collapse.Panel key={i} header={c.name} itemKey={i.toString()}>
            {c.comments.map((com) => (
              <CommentItem comment={com} key={com.id}></CommentItem>
            ))}
            {c.comments.length === 0 && (
              <span style={{ opacity: 0.5 }}>没有评论呢</span>
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ResumeCommentTab;
