import "./index.scss";

import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { IconChevronDown } from "@douyinfe/semi-icons";
import { Select } from "@douyinfe/semi-ui";

import { usePeriods } from "../../api/swr/association/usePeriods";
import { useCurPeriod } from "../../hooks/useCurPeriod";
import { useJumpTo } from "../../hooks/useJumpTo";
import HeaderNavs from "./components/HeaderNavs";
import HeaderUserInfo from "./components/HeaderUserInfo";

type TheHeaderProps = {};

const TheHeader: FC<TheHeaderProps> = () => {
  const location = useLocation();

  const { periods } = usePeriods();

  const loc = useLocation();
  const jumpTo = useJumpTo();

  // curPeriod 是路径中的 periodID 对应的 period
  const curPeriod = useCurPeriod();

  if (location.pathname === "/login") return null;

  return (
    <>
      <div className="t_header">
        <Select
          className="t_header-title"
          value={curPeriod?.name}
          onChange={(periodID) => {
            jumpTo(loc.pathname, { periodID: periodID as string });
          }}
          triggerRender={() => (
            <div>
              {curPeriod?.name || "招新系统"}
              <IconChevronDown style={{ margin: "0 8px" }} />
            </div>
          )}
          optionList={periods?.map((p) => ({
            label: p.name,
            value: p.id,
          }))}
          style={
            curPeriod && !curPeriod.current
              ? {
                  color: "var(--by-color)",
                }
              : {}
          }></Select>

        <HeaderNavs></HeaderNavs>

        <div className="spacer"></div>

        <HeaderUserInfo></HeaderUserInfo>
      </div>
      <div className="t_header-placeholder"></div>
    </>
  );
};

export default TheHeader;
