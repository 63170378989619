import useSWR from "swr";

import { getPeriodWorkPath, getPeriodWorkReq } from "../../http/association/getPeriodWork";

export function usePeriodWork() {
  const { isValidating, mutate, data, error } = useSWR(
    getPeriodWorkPath,
    getPeriodWorkReq
  );

  return {
    periodWork: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
