export type NoticeTemplateType = {
  template_id: number;
  title: string;
  type: NotifyType;
  content: string;
  group_id: number;
  group_name: string;
};

export enum NotifyType {
  /**不限 */
  ANY = 0,
  /**通过 */
  OK = 1,
  /**淘汰 */
  OUT = 2,
}

export const notifyType2Name: Record<NotifyType, string> = {
  [NotifyType.ANY]: "不限",
  [NotifyType.OK]: "通过",
  [NotifyType.OUT]: "淘汰",
};
