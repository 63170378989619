import "./index.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

import {
  IconChevronLeft,
  IconClose,
  IconHelpCircle,
} from "@douyinfe/semi-icons";
import {
  Button as SemiBtn,
  ButtonGroup,
  Select,
  Space,
  SplitButtonGroup,
  Tooltip,
  Typography,
} from "@douyinfe/semi-ui";

import { addTemplateReq } from "../../api/http/template/addTemplate";
import { editTemplateReq } from "../../api/http/template/editTemplate";
import { deleteTemplateReq } from "../../api/http/template/deleteTemplate";
import { getTemplatesPath } from "../../api/http/template/getTemplates";
import Button from "../../components/Button";
import { NoticeTemplateType, NotifyType } from "../../models/template";
import { showToast } from "../../utils/showToast";
import SelectState from "./components/SelectState";
import { useTemplateOfGroup } from "./hooks/useTemplateOfGroup";

const { Paragraph, Text } = Typography;

const defaultTemplate: NoticeTemplateType = {
  content: "",
  group_id: 0,
  group_name: "",
  template_id: -1,
  title: "",
  type: 0,
};

export default function NoticeTemplate() {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const [editedTemplate, setEditedTemplate] =
    useState<NoticeTemplateType>(defaultTemplate);
  const [showWorkspace, setShowWorkspace] = useState(false);

  const isCreating = editedTemplate.template_id === -1;
  const [templateState, setTemplateState] = useState<NotifyType>(0);

  const { curGroupName, filteredTemplates, groupList, setCurGroupName } =
    useTemplateOfGroup();

  async function handleSubmit() {
    const { content, title, template_id } = editedTemplate;

    if (!content.trim()) {
      return showToast("模板内容不能为空", "error");
    }
    if (!title.trim()) {
      return showToast("模板标题不能为空", "error");
    }

    const success = await (isCreating
      ? addTemplateReq({ content, title, type: templateState })
      : editTemplateReq({
          ...editedTemplate,
        }));

    if (!success) {
      return;
    }

    showToast(`${isCreating ? "创建" : "编辑"}成功!`, "success");
    setTemplateState(0);
    setEditedTemplate(defaultTemplate);
    setShowWorkspace(false);
    mutate(getTemplatesPath);
  }

  async function handleDelete(template: NoticeTemplateType) {
    const success = await deleteTemplateReq({ ...template });
    if (!success) {
      return;
    }
    showToast(`删除成功!`, "success");
    mutate(getTemplatesPath);
  }

  return (
    <div className="scroll_container">
      <div className="notice_template">
        <div className="notice_template-header">
          <IconChevronLeft
            className="notice_template-header-icon"
            onClick={() => navigate("..")}
          />
          <Tooltip content="保存通知模板可以让你在多轮通知中复用一样的话术">
            通知模板
            <IconHelpCircle style={{ marginLeft: "0.5rem", opacity: 0.3 }} />
          </Tooltip>
          <div className="spacer"></div>

          <Space spacing="loose">
            <Select
              value={curGroupName}
              onChange={(v) => setCurGroupName(v?.toString() || "全部")}
              defaultValue="全部"
              style={{ width: "7rem" }}
            >
              <Select.Option value="全部">全部</Select.Option>
              {groupList?.map((g) => (
                <Select.Option key={g.id} value={g.name}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>

            <SplitButtonGroup>
              <SemiBtn theme="solid">通知模板</SemiBtn>
              <SemiBtn
                onClick={() => showToast("TODO: 跳转到问卷草稿界面", "warning")}
              >
                问卷草稿
              </SemiBtn>
            </SplitButtonGroup>
          </Space>
        </div>
        <div className="notice_template-templates">
          <div
            className="notice_template-templates-create"
            onClick={() => {
              setEditedTemplate({ ...defaultTemplate });
              setShowWorkspace(true);
            }}
          >
            新建+
          </div>
          {filteredTemplates?.map((template) => (
            <div
              className="notice_template-templates-item"
              key={template.template_id}
            >
              <div className="notice_template-templates-item-header">
                <Text ellipsis={{ showTooltip: true }} className="nttih-title">
                  {template.title}
                </Text>
                <div className="spacer" />
                <Button
                  content="编辑"
                  type="filled"
                  onClick={() => {
                    setEditedTemplate({ ...template });
                    setShowWorkspace(true);
                  }}
                />
                <Button
                  onClick={() => {
                    handleDelete({ ...template });
                  }}
                  content="删除"
                />
              </div>
              <Paragraph
                className="notice_template-templates-item-content"
                ellipsis={{ rows: 5, showTooltip: { type: "popover" } }}
              >
                {template.content}
              </Paragraph>
            </div>
          ))}
        </div>
        {/* <Pagination
          total={300}
          pageSize={9}
          nextText="下一页"
          prevText="上一页"
          className="notice_template-pager"
        ></Pagination> */}
        <div
          className={`notice_template-workspace ${
            showWorkspace ? "fade_in" : ""
          }`}
        >
          <div className="notice_template-workspace-header">
            {`${isCreating ? "创建" : "编辑"}模板`}
            <div className="spacer" />
            <div
              className="notice_template-workspace-header-close"
              onClick={() => setShowWorkspace(false)}
            >
              <IconClose />
            </div>
          </div>
          <div className="notice_template-workspace-setting">
            <div className="notice_template-workspace-setting-key">
              模板名称
            </div>
            <input
              type="text"
              className="notice_template-workspace-setting-input"
              value={editedTemplate.title}
              onChange={(e) =>
                setEditedTemplate({ ...editedTemplate, title: e.target.value })
              }
            />
          </div>
          <div className="notice_template-workspace-setting">
            <div className="notice_template-workspace-setting-key">
              模板分类
            </div>
            <SelectState
              selection={templateState}
              setSelection={setTemplateState}
            />
          </div>
          <div className="notice_template-workspace-setting content">
            <div className="notice_template-workspace-setting-key">
              文字内容
            </div>
            <textarea
              className="notice_template-workspace-setting-input textarea"
              value={editedTemplate.content}
              onChange={(e) =>
                setEditedTemplate({
                  ...editedTemplate,
                  content: e.target.value,
                })
              }
            />
          </div>
          <Button
            onClick={handleSubmit}
            content={isCreating ? "创建" : "完成"}
            type="filled"
          />
        </div>
      </div>
    </div>
  );
}
