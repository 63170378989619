import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function getQueryParams(query = ""): Record<string, string> {
  if (query.includes("http")) query = `?${query.split("?").pop()}`;

  const params = new URLSearchParams(query);
  const queriedParams: Record<string, string> = {};
  params.forEach((value: string, key: string) => {
    queriedParams[key] = value;
  });

  return queriedParams;
}

export type QueryKeys =
  | "resumeID" // 当前查看的简历 ID
  | "groupID" // 当前查看的组 ID
  | "mainTab" // 简历详情中间部分的tab是 报名表 还是 评论
  | "actionTab" // 简历详情右边部分的tab是 快速评价 还是 聊天室
  | "hint" // 登录页的错误原因提示
  | "kw" // 简历详情页的搜索关键字
  | "sort" // 简历详情页对简历的排序方式
  | "periodID"; // 当前周期
export type Query = { [K in QueryKeys]?: string };

/**
 * 获得当前路由的 query 对象\
 * **会**随路由变化自动更新
 */
export function useQuery() {
  const { search } = useLocation();

  const [query, setQuery] = useState<Query>(getQueryParams(search) as Query);

  useEffect(() => {
    setQuery(getQueryParams(search) as Query);
  }, [search]);

  return query;
}
