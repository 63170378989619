import "./index.scss";

import { FC } from "react";

import { Comment } from "../../../../../../../models/comment";

type CommentItemProps = {
  comment: Comment;
};

const CommentItem: FC<CommentItemProps> = ({ comment }) => {
  return (
    <div className="r-comment_item">
      <div className="r-comment_item-header">
        <div className="r-comment_item-header-name">{comment.nickname}</div>
      </div>
      <div className="r-comment_item-content">{comment.content}</div>
    </div>
  );
};

export default CommentItem;
