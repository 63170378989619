import "./index.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IconSearch, IconUpload } from "@douyinfe/semi-icons";
import { Input, TabPane, Tabs, Tooltip } from "@douyinfe/semi-ui";

import { useNoticeList } from "../../api/swr/notify/useNoticeList";
import Button from "../../components/Button";
import { showToast } from "../../utils/showToast";
import DialogNextTurn from "./components/DialogNextTurn";
import InterviewersTable from "./components/InterviewersTable";
import QuesResContainer from "./components/QuesResContainer";
import QuestionnaireState from "./components/QuestionnaireState";
import TurnState from "./components/TurnState";
import { useScheduleInfo } from "./hooks/useScheduleInfo";
import { useCurTurnInfo } from "../../api/swr/turn/useCurTurnInfo";
import UploadState from "./components/UploadState";
import { useScheduleDetail } from "../../api/swr/schedule/useScheduleDetail";

const useUploadState = () => {
  const { turnInfo: curTurnInfo, loading: curTurnInfoLoading } =
    useCurTurnInfo();
  const [isCurTurnUploadOpen, setIsCurTurnUploadOpen] = useState(false);

  useEffect(() => {
    if (curTurnInfoLoading) return;
    if (!curTurnInfo || !curTurnInfo.end_time) return;
    const callback = () => {
      const endTimestamp = curTurnInfo.end_time * 1000;
      setIsCurTurnUploadOpen(() => endTimestamp > new Date().getTime());
    };
    callback();

    const I = setInterval(callback, 30 * 1000);
    return () => {
      clearInterval(I);
    };
  }, [curTurnInfo, curTurnInfoLoading]);
  return {
    isCurTurnUploadOpen,
    curTurnInfoLoading,
    curTurnInfo,
  };
};

export default function Notice() {
  const [activeKey, setActiveKey] = useState("1");
  const navigate = useNavigate();
  const { noticeList } = useNoticeList();
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const { questionnaireInfo } = useScheduleInfo();

  const [showManage, setShowManage] = useState(false);

  const [showNextTurnDialog, setShowNextTurnDialog] = useState(false);
  const { scheduleDetail } = useScheduleDetail();

  useEffect(() => {
    if (activeKey === "2") setShowManage(true);
  }, [activeKey]);

  const { isCurTurnUploadOpen, curTurnInfoLoading, curTurnInfo } =
    useUploadState();

  return (
    <div className="notice">
      <div className="notice-turn">
        <div className="notice-turn-next">
          <div>流程结束后可以进入下一轮</div>
          <div className="spacer"></div>
          <Button
            content="进入下一轮"
            type="filled"
            className={
              questionnaireInfo && questionnaireInfo.open ? "disable" : ""
            }
            onClick={() => {
              if (!questionnaireInfo || !questionnaireInfo.open)
                setShowNextTurnDialog(true);
              else showToast("当前流程尚未结束", "error");
            }}
          />
        </div>
        {questionnaireInfo && <QuestionnaireState {...questionnaireInfo} />}
        {!curTurnInfoLoading && curTurnInfo && curTurnInfo.end_time ? (
          <UploadState
            open={isCurTurnUploadOpen}
            closeTime={curTurnInfo.end_time}
          />
        ) : null}
        {noticeList.map((turn) => (
          <TurnState {...turn} key={turn.id} />
        ))}
        <Button
          content="展开"
          type="light_filled"
          className="notice-turn-button"
        />
        <div className="notice-turn-all">全部报名表</div>
      </div>
      <div className="notice-detail">
        <Tabs
          type="line"
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
        >
          <TabPane tab="通知名单" itemKey="1">
            <div className="notice-detail-nav">面试者{">"}选择被通知者</div>
            <InterviewersTable filterValue={filterValue} />
          </TabPane>
          <TabPane tab="问卷结果" itemKey="2">
            <QuesResContainer
              schedule_id={scheduleDetail ? scheduleDetail.id : -1}
              showManage={showManage}
              onCancel={() => setShowManage(false)}
            />
          </TabPane>
        </Tabs>
        {activeKey === "1" && (
          <div className="notice-detail-opts">
            <Input
              value={searchValue}
              showClear
              onClear={() => setFilterValue("")}
              onChange={(value) => setSearchValue(value)}
              onEnterPress={() => setFilterValue(searchValue)}
              prefix={<IconSearch className="r-input-icon" />}
              className="r-input"
            ></Input>
            <Button type="filled" onClick={() => navigate("/notice/create")}>
              <Tooltip content="将通知下表中勾选的报名者">新建通知</Tooltip>
            </Button>
            <Button
              content="通知模板"
              onClick={() => navigate("/notice/template")}
            />
          </div>
        )}
      </div>
      <DialogNextTurn
        showNextTurnDialog={showNextTurnDialog}
        setShowNextTurnDialog={setShowNextTurnDialog}
      />
    </div>
  );
}
