import moment from "moment";

export const countdownFromNow = (time?: number) => {
  const allSeconds = moment.duration(moment(time).diff(Date.now())).asSeconds();

  const days = Math.floor(allSeconds / 60 / 24 / 60);
  const hours = Math.floor((allSeconds / 60 / 60) % 24);
  const minutes = Math.floor((allSeconds / 60) % 60);

  return { days, hours, minutes };
};
