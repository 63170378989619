import useSWRImmutable from "swr/immutable";

import {
    getCurTurnSchedulesPath, getCurTurnSchedulesReq
} from "../../http/schedule/getCurTurnSchedules";

export function useCurTurnSchedules() {
  const { isValidating, mutate, data, error } = useSWRImmutable(
    getCurTurnSchedulesPath,
    getCurTurnSchedulesReq
  );

  return {
    schedules: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
