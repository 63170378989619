import useSWRImmutable from "swr/immutable";

import { getScheduleDetailPath, getScheduleDetailReq } from "../../http/schedule/getScheduleDetail";
import { useCurTurnSchedules } from "./useCurTurnSchedules";

export function useScheduleDetail() {
  const { schedules } = useCurTurnSchedules();

  const id =
    schedules && schedules.length
      ? schedules[schedules.length - 1].id
      : undefined;
  const { isValidating, mutate, data, error } = useSWRImmutable(
    `${getScheduleDetailPath}/${id}`,
    () => getScheduleDetailReq(id)
  );

  return {
    scheduleDetail: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
