import { Period } from "../../../models/period";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getPeriodsPath = "/association/period";

/**
 * 获得所有面试周期的信息
 */
export async function getPeriodsReq(): Promise<Period[] | null> {
  const res = await request<Period[]>(
    {
      url: getPeriodsPath,
      method: "GET",
    }
    // { notAutoRedirect: true, notShowHint: true }
  );

  return res;
}
