import useSWR from "swr";
import { AttachmentParams } from "../../../models/attachment";
import {
  getAttachments,
  getAttachmentsPath,
} from "../../http/attachment/getAttachments";

export function useAttachments(params: AttachmentParams) {
  // 这里不需要手动更改数据缓存
  const { data, error } = useSWR(
    `${getAttachmentsPath}?turn=${params.turn}`,
    () => getAttachments(params)
  );

  // 根据每个人的附件长度进行由高到低的排序
  if (data) {
    data.sort((a, b) => {
      return b.attachment.length - a.attachment.length;
    });
  }

  return {
    attachmentItems: data,
    error,
  };
}
