import "./index.scss";

import { FC, useEffect, useRef, useState } from "react";
import { IconEdit2Stroked } from "@douyinfe/semi-icons";
import editIcon from "../../assets/edit.svg";

type EditableDivProps = {
  content: string;
  setContent: (s: string) => void;
  /** 若不传则由内部控制, 否则以传入为准 */
  editable?: boolean;
  setEditable?: (canEdit: boolean) => void;
};

const EditableDiv: FC<EditableDivProps> = ({
  content,
  setContent,
  editable: outerEditable,
  setEditable: outerSetEditable,
}) => {
  const [inputWidth, setInputWidth] = useState(content.length + "rem");
  const [innerEditable, setInnerEditable] = useState(!!outerEditable);

  const inputRef = useRef<HTMLInputElement>(null);
  const dummyRef = useRef<HTMLDivElement>(null);

  const setEditable = () => {
    setInnerEditable(true);
    outerSetEditable && outerSetEditable(true);

    document.addEventListener(
      "click",
      () => {
        setInnerEditable(false);
        outerSetEditable && outerSetEditable(false);
      },
      { once: true }
    );
  };

  useEffect(() => {
    if (!inputRef.current) return;
    if (outerEditable === undefined) {
      if (innerEditable) inputRef.current.focus();
    } else {
      if (outerEditable) inputRef.current.focus();
    }
  }, [outerEditable, innerEditable, inputRef.current]);

  useEffect(() => {
    setInputWidth(
      dummyRef.current
        ? dummyRef.current.offsetWidth + 1 + "px"
        : content.length + "rem"
    );
  }, [content]);

  return (
    <div
      className="editable_div"
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={setEditable}
    >
      <div className="editable_div-dummy" ref={dummyRef}>
        {content}
      </div>

      <input
        disabled={
          !(outerEditable === undefined ? innerEditable : outerEditable)
        }
        type="text"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className="editable_div-input"
        ref={inputRef}
        style={{ width: inputWidth }}
      />
      {/* <img
        src={editIcon}
        alt="edit"
        className="editable_div-icon"
        onClick={setEditable}
      /> */}
      <div className="editable_div-icon">
        <IconEdit2Stroked />
      </div>
    </div>
  );
};

export default EditableDiv;
