import request from "../../request";

export type changePasswordReqData = {
  origin: string;
  password: string;
};

export const changePasswordPath = "/user/password";

/**
 * 修改密码
 * @returns
 */
export async function changePasswordReq(data: changePasswordReqData) {
  const res = await request<string>({
    url: `${changePasswordPath}`,
    method: "PUT",
    data: data,
  });

  return res !== null;
}
