import { NoticeTemplateType } from "../../../models/template";
import request from "../../request";

export type AddTemplateReqData = Pick<
  NoticeTemplateType,
  "content" | "title" | "type"
>;

export const addTemplatePath = "/notify/template";

/**
 * 创建一个通知模板
 * @returns
 */
export async function addTemplateReq(data: AddTemplateReqData) {
  const res = await request<string>({
    url: addTemplatePath,
    method: "POST",
    data,
  });

  return res !== null;
}
