import { Group } from "../../../models/group";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getGroupListPath = "/group";

/**
 * 获得冰岩所有组的列表
 */
export async function getGroupListReq(): Promise<Group[] | null> {
  const res = await request<Group[]>({
    url: getGroupListPath,
    method: "GET",
  });

  return res;
}
