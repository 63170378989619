import { FC } from "react";

const CEmpty: FC = () => {
  return (
    <p
      style={{
        textAlign: "center",
        padding: "20px 0 25px",
        opacity: 0.5,
      }}
    >
      暂无数据
    </p>
  );
};

export default CEmpty;
