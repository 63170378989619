import "./index.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Switch, TabPane, Tabs } from "@douyinfe/semi-ui";

import { setAllowOfRecruitReq } from "../../api/http/group/setAllowOfRecruit";
import { usePeriods } from "../../api/swr/association/usePeriods";
import { usePeriodWork } from "../../api/swr/association/usePeriodWork";
import { useAllowOfRecruit } from "../../api/swr/group/useAllowOfRecruit";
import { useNoticeList } from "../../api/swr/notify/useNoticeList";

import { showToast } from "../../utils/showToast";
import DialogUpdateSignupState from "./components/DialogUpdateSignupState";
import GroupInfoList from "./components/GroupInfoList";
import OverviewTotal from "./components/OverviewTotal";
import UnconfirmedResume from "./components/UnconfirmedResume";

export default function Home() {
  const { allowOfRecruit } = useAllowOfRecruit();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const navigate = useNavigate();

  const { periods } = usePeriods();

  const currentPeriod = periods && periods.find((p) => p.current);

  useEffect(() => {
    if (!allowOfRecruit) return;
    setIsSigningUp(() => allowOfRecruit.allow_post);
  }, [allowOfRecruit]);

  const switchAllowOfRecruit = async () => {
    if (!allowOfRecruit) return;
    setIsSigningUp((isSigningUp) => !isSigningUp);

    const res = await setAllowOfRecruitReq(!allowOfRecruit.allow_post);
    if (res) {
      showToast("修改成功", "success");
      setShowDialog(false);
    }
  };

  const { periodWork } = usePeriodWork();

  return (
    <div className="scroll_container">
      <div className="home">
        <div className="home-overview">
          <div className="home-overview-title">
            <div className="home-overview-title-cur">招新概况</div>
            <div
              className="home-overview-title-history"
              onClick={() => navigate("/home/history")}
            >
              历史招新
            </div>
          </div>
          <OverviewTotal periodInfo={currentPeriod} />

          <GroupInfoList></GroupInfoList>
        </div>
        <div className="home-group_work">
          <div className="home-group_work-title">
            <div className="home-group_work-title-cur">本组工作</div>
            <div className="home-group_work-title-switch">
              <Switch
                checked={isSigningUp}
                onChange={() => setShowDialog(true)}
              />
              <div
                className={`home-group_work-title-switch-text ${
                  isSigningUp ? "open" : "close"
                }`}
              >
                {isSigningUp ? "报名表开启中" : "报名表已关闭"}
              </div>
            </div>
          </div>

          <div className="home-group_work-current">
            <div className="home-group_work-current-item turn">
              <div>
                <div className="home-group_work-current-item-title">
                  目前轮次
                </div>
                <div className="home-group_work-current-item-content">
                  {periodWork?.turn?.name}
                </div>
              </div>
              {/* <div className="home-group_work-current-item-opt">编辑名称</div> */}
            </div>
            <div className="home-group_work-current-item">
              <div className="home-group_work-current-item-title">目前通知</div>
              <div className="home-group_work-current-item-content">
                {periodWork?.last_notify?.name}
              </div>
            </div>
          </div>
          <div className="home-group_work-mine">
            <Tabs type="line">
              <TabPane
                tab={`未表态 (${periodWork?.not_attitude?.length || 0})`}
                itemKey="1"
                className="home-group_work-mine-unconfirmed"
              >
                {periodWork?.not_attitude?.map((resume) => (
                  <UnconfirmedResume
                    {...resume}
                    id={resume.resume_id}
                    key={resume.resume_id}
                  />
                ))}
              </TabPane>
              <TabPane
                tab={`未评论 (${periodWork?.not_comment?.length || 0})`}
                itemKey="2"
                className="home-group_work-mine-unconfirmed"
              >
                {periodWork?.not_comment?.map((resume) => (
                  <UnconfirmedResume
                    {...resume}
                    id={resume.resume_id}
                    key={resume.resume_id}
                  />
                ))}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <DialogUpdateSignupState
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        onOk={switchAllowOfRecruit}
      />
    </div>
  );
}
