import "./index.scss";

import { FC } from "react";

import { ResumeDetail } from "../../../../../../../models/resume";

type AnswerItemProps = {
  QA: ResumeDetail["custom"][number];
};

const AnswerItem: FC<AnswerItemProps> = ({ QA }) => {
  return (
    <div className="r_ans-item">
      <div className="r_ans-item-question">{QA.question}</div>
      <div className="r_ans-item-answer">{QA.answer}</div>
    </div>
  );
};

export default AnswerItem;
