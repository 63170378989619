import { NoticeInterviewers } from "../../../../../models/notice";
import { DataType } from "../index";

const notifyType: DataType["state"][] = [
  "disabled",
  "success",
  "error",
  "filled",
  "unfilled",
];

export const dataTransformer = (curTurnInterviewers?: NoticeInterviewers) => {
  if (!curTurnInterviewers) return [];

  const interviewers: DataType[] = curTurnInterviewers.notifies.map(
    (notify) => {
      return {
        id: notify.notify_id,
        major: notify.resume.major,
        name: notify.resume.name,
        score: notify.resume.attitudes,
        tags: notify.resume.tags,
        state: notifyType[notify.notify_type],
        phone: notify.resume.phone,
        schedule: notify.schedules && notify.schedules.length > 0,
      };
    }
  );

  return interviewers;
};
