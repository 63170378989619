import React from "react";

export default class ErrorBoundary extends React.Component<
  {},
  { hasError?: boolean },
  {}
> {
  constructor(p: {}) {
    super(p);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(e: Error) {
    console.error("error in boundary:");
    console.error(e);
    alert("请在最新版 Chrome 浏览器上查看此网站");
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return <h1>请在最新版 Chrome 浏览器上查看此网站</h1>;
    }

    return this.props.children;
  }
}
