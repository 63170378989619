import useSWR from "swr";

import {
  getTemplatesPath,
  getTemplatesReq,
} from "../../http/template/getTemplates";

/**
 * 得到通知模板的响应式数据
 */
export function useTemplates() {
  const { isValidating, mutate, data, error } = useSWR(
    getTemplatesPath,
    getTemplatesReq
  );

  return {
    templates: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
