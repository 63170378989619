import { NoticeTemplateType } from "../../../models/template";
import request from "../../request";

export type DeleteTemplateReqData = NoticeTemplateType;
export const deleteTemplatePath = "/notify/template";

/**
 * 删除一个通知模板
 * @returns
 */
export async function deleteTemplateReq(data: DeleteTemplateReqData) {
  const res = await request<string>({
    url: `${deleteTemplatePath}/${data.template_id}`,
    method: "DELETE",
    data,
  });

  return res !== null;
}
