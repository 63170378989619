import { ScheduleBrief } from "../../../models/schedule";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getCurTurnSchedulesPath = "/schedule/current";

/**
 * 获得本组本轮的时间表
 * @returns
 */
export async function getCurTurnSchedulesReq(): Promise<
  ScheduleBrief[] | null
> {
  await wait(1000);

  // return [
  //   {
  //     id: 1,
  //     title: "前端组第一次时间表",
  //   },
  //   {
  //     id: 2,
  //     title: "前端组第2次时间表",
  //   },
  //   {
  //     id: 3,
  //     title: "前端组第3次时间表",
  //   },
  //   {
  //     id: 4,
  //     title: "前端组第4次时间表",
  //   },
  // ];

  const res = await request<ScheduleBrief[]>({
    url: getCurTurnSchedulesPath,
    method: "GET",
  });

  return res;
}
