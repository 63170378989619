import useSWR from "swr";

import { getGroupListPath, getGroupListReq } from "../../http/group/getGroupList";

/** 响应式的组别列表 */
export function useGroupList() {
  const { isValidating, mutate, data, error } = useSWR(getGroupListPath, () =>
    getGroupListReq()
  );

  return {
    groupList: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
