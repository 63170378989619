import "./index.scss";

import { FC, useEffect, useState } from "react";

import { IconChevronDown, IconExit, IconEdit } from "@douyinfe/semi-icons";
import { Dropdown, Input, Modal } from "@douyinfe/semi-ui";

import { useSelf } from "../../../../api/swr/member/useSelf";
import { TOKEN_KEY } from "../../../../constants/token";
import { changePasswordReq } from "../../../../api/http/member/changePassword";
import { showToast } from "../../../../utils/showToast";

type HeaderUserInfoProps = {};

const HeaderUserInfo: FC<HeaderUserInfoProps> = () => {
  function exit() {
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload();
  }

  const self = useSelf();

  const [dialogShow, setDialogShow] = useState(false);
  const [pastPassword, setPastPassword] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");

  return (
    <>
      <Modal
        title="修改密码"
        maskClosable={false}
        visible={dialogShow}
        onOk={async () => {
          if (!password || !checkPassword || !pastPassword)
            return showToast("输入内容为空", "error");
          if (password !== checkPassword)
            return showToast("新密码不一致", "error");

          const success = await changePasswordReq({
            origin: pastPassword,
            password,
          });

          if (success) {
            showToast("修改成功", "success");
            setDialogShow(() => false);
            setPassword(() => "");
            return;
          }
          showToast("修改失败", "error");
        }}
        onCancel={() => {
          setDialogShow(() => false);
          setPassword(() => "");
        }}
      >
        <div className="header-user_input_wrap">
          <Input
            mode="password"
            value={pastPassword}
            onChange={(val) => {
              setPastPassword(() => val);
            }}
            placeholder="输入原密码"
          />

          <Input
            mode="password"
            value={password}
            onChange={(val) => {
              setPassword(() => val);
            }}
            placeholder="输入新密码"
          />

          <Input
            mode="password"
            value={checkPassword}
            onChange={(val) => {
              setCheckPassword(() => val);
            }}
            placeholder="确认新密码"
          />
        </div>
      </Modal>
      <Dropdown
        position={"bottomRight"}
        style={{ width: "7rem" }}
        render={
          <Dropdown.Menu>
            <Dropdown.Item onClick={exit} type="danger" icon={<IconExit />}>
              退出
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setDialogShow(() => true);
              }}
              icon={<IconEdit />}
            >
              修改密码
            </Dropdown.Item>
          </Dropdown.Menu>
        }
      >
        <div className="header-user_info">
          {self.self?.nickname || self.self?.username || self.self?.id}
          <IconChevronDown />
        </div>
      </Dropdown>
    </>
  );
};

export default HeaderUserInfo;
