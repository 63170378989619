import useSWR from "swr";

import {
    getSchedulesOfMyGroupPath, getSchedulesOfMyGroupReq
} from "../../http/schedule/getSchedulesOfMyGroup";

export function useSchedulesOfMyGroup() {
  const { isValidating, mutate, data, error } = useSWR(
    getSchedulesOfMyGroupPath,
    () => getSchedulesOfMyGroupReq()
  );

  return {
    schedules: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}
