import "./index.scss";

import { FC, useEffect } from "react";

import { IllustrationNoResult } from "@douyinfe/semi-illustrations";
import { Empty, Spin } from "@douyinfe/semi-ui";

import { useCurResumeList } from "../../../../../api/swr/resume/useResumeList";
import { useJumpTo } from "../../../../../hooks/useJumpTo";
import { useQuery } from "../../../../../hooks/useQuery";
import NavItem from "./components/NavItem";

import { getResumeListReq } from "../../../../../api/http/resume/getResumeList";  

type NavListProps = {};

const NavList: FC<NavListProps> = () => {
  const { groupID, resumeID } = useQuery();

  const resumes = useCurResumeList();

  const jumpTo = useJumpTo();

  useEffect(() => {
    if (!resumes || !resumes.length) return;
    if (resumes.find((r) => r.id === parseInt(resumeID || ""))) {
      /* PASS */
    } else {
      jumpTo("/resume", { resumeID: resumes[0].id }, false, true);
    }
  }, [resumes, groupID, resumeID]);

  function clickNav(resumeID: number) {
    jumpTo("/resume", { resumeID });
  }

  return (
    <div className="r-nav-list">
      <Spin spinning={!resumes} style={{ minHeight: "200px" }}>
        {(resumes || []).map((resume, index) => (
          <NavItem
            onClick={() => clickNav(resume.id)}
            active={resume.id == parseInt(resumeID || "")}
            key={resume.id}
            resume={resume}
            index={index + 1}
          ></NavItem>
        ))}
        {resumes?.length === 0 && (
          <Empty
            image={<IllustrationNoResult style={{ width: 150, height: 150 }} />}
            description={"搜索无结果"}
            style={{ paddingTop: "4rem" }}
          />
        )}
      </Spin>
    </div>
  );
};

export default NavList;
