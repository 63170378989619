import { usePeriods } from "../api/swr/association/usePeriods";
import { useQuery } from "./useQuery";

export function useCurPeriod() {
  const { periods } = usePeriods();
  const { periodID } = useQuery();

  const foundPeriod = periods?.find((p) => p.id === Number(periodID));
  if (foundPeriod) return foundPeriod;

  // 没有路径参数时返回最新的周期
  return periods?.find((p) => p.current);
}
