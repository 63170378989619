import request from "../../request";

export type SendNoticeReqData = {
  notify_id_list: number[];
  content: string;
  // 是否通过, 为 true 代表通过
  passed: boolean;
  // 缺省则不发出时间安排表
  schedule_id: number;
  email: boolean;
  sms: boolean;
};

export const sendNoticePath = "/notify/table/send";

/**
 * 发送一波通知
 */
export async function sendNoticeReq(data: SendNoticeReqData) {
  const res = await request<string>({
    url: sendNoticePath,
    method: "POST",
    data,
  });

  return res !== null;
}
