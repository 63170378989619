import "./index.scss";

import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelf } from "../../../../api/swr/member/useSelf";
import { useJumpTo } from "../../../../hooks/useJumpTo";
import { MemberIdentity } from "../../../../models/member";
import { routesObj } from "../../../../routes";

type HeaderNavProps = {};

const HeaderNav: FC<HeaderNavProps> = () => {
  const pathname = useLocation().pathname;

  const jumpTo = useJumpTo();

  const { self } = useSelf();

  const renderPaths =
    self?.identity === MemberIdentity.GROUP_MANAGER
      ? new Set(["/home", "/resume", "/notice", "/attachment"])
      : new Set(["/home", "/resume","/attachment"]);

  return (
    <div className="header-navs">
      {routesObj
        .filter((r) => r.cnName && r.path && renderPaths.has(r.path))
        .map((r) => (
          <div
            key={r.path}
            className={`header-navs-item ${
              pathname.startsWith(r.path!) ? "active" : ""
            }`}
            onClick={() => {
              // 跳转时，合并新旧参数，目的是保留原来的 periodID
              jumpTo(r.path || "/", {}, false);
            }}>
            {r.cnName}
          </div>
        ))}
    </div>
  );
};

export default HeaderNav;
