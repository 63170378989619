import "./index.scss";

import { IconAlignTop } from "@douyinfe/semi-icons";

import { useJumpTo } from "../../../../hooks/useJumpTo";
import { PeriodGroupDetail } from "../../../../models/period";

export type GroupInfoProps = PeriodGroupDetail & {
  toggleTopped: () => void;
  topped?: boolean;
};

export default function GroupInfo(props: GroupInfoProps) {
  const {
    now_count,
    group_name,
    signup_count,
    topped,
    toggleTopped,
    group_id,
  } = props;

  const jumpTo = useJumpTo();

  return (
    <div className={`group_info ${topped ? "topped" : "common"}`}>
      <div className="group_info-content">{group_name}</div>
      <div className="group_info-content count">{now_count}</div>
      <div className="group_info-content count">{signup_count}</div>
      <div
        className="group_info-content resume_btn"
        onClick={() => jumpTo("/resume", { groupID: group_id })}
      >
        查看简历
      </div>
      <div className="group_info-top" onClick={toggleTopped}>
        <IconAlignTop className="group_info-top-icon" />
      </div>
    </div>
  );
}
