import request from "../../request";

export type DeleteCommentReqData = {
  commentID: number;
};

/**
 * 删除一条评论
 * @returns
 */
export async function deleteCommentReq({ commentID }: DeleteCommentReqData) {
  const res = await request<string>({
    url: `/turn/comment?comment_id=${commentID}`,
    method: "DELETE",
  });

  return res !== null;
}
