import { ScheduleEdit } from "../../../models/schedule";
import request from "../../request";

export type EditScheduleReqData = ScheduleEdit;

export const editSchedulePath = "/schedule";

/**
 * 修改一份问卷模板
 * @returns
 */
export async function editScheduleReq(data: EditScheduleReqData) {
  const res = await request<string>({
    url: `${editSchedulePath}/${data.schedule_id}`,
    method: "PUT",
    data,
  });

  return res !== null;
}
