import "./index.scss";
import "moment/locale/zh-cn";

import { useState } from "react";

import Button from "../../../../components/Button";
import { countdownFromNow } from "../../utils/countdownFromNow";
import DialogChangeTime from "../DialogChangeTime";

export type DialogType = "setTime" | "updateTime" | "closeQuestionnaire" | null;

export type QuestionnaireStateProps = {
  open: boolean;
  closeTime?: number;
  title: string;
};

export default function QuestionnaireState(props: QuestionnaireStateProps) {
  const [dialogType, setDialogType] = useState<DialogType>(null);

  const { closeTime, open, title } = props;

  const { days, hours, minutes } = countdownFromNow(closeTime || 0);

  const timeTip =
    days || hours || minutes
      ? `${days ? `${days}天` : ""}${hours ? `${hours}小时` : ""}${
          minutes ? `${minutes}分钟` : ""
        }后自动关闭问卷`
      : "问卷即将关闭";

  return (
    <div className="questionnaire_state">
      <div className="questionnaire_state-info">
        <div className="questionnaire_state-info-title">{title}</div>
        {open && (
          <div className="questionnaire_state-info-countdown ellipsis">
            {closeTime && closeTime !== -1 ? timeTip : "未设置关闭时间"}
          </div>
        )}
      </div>
      <div className="spacer"></div>
      <Button
        content={open ? "问卷开启中" : "问卷已关闭"}
        type="filled"
        onClick={() => {
          if (!open) return;
          setDialogType(
            closeTime && closeTime !== -1 ? "updateTime" : "setTime"
          );
        }}
        className={open ? "" : "close"}
      />
      <DialogChangeTime
        type={dialogType}
        setDialogType={setDialogType}
        closeTime={dialogType === "updateTime" ? closeTime : void 0}
      />
    </div>
  );
}
