import moment from "moment";
import { useEffect, useState } from "react";

import { useGroupList } from "../../../api/swr/group/useGroupList";
import { useSelf } from "../../../api/swr/member/useSelf";
import { Schedule, ScheduleEdit, TimeFrame } from "../../../models/schedule";

export function useSchedule() {
  const { self } = useSelf();
  const { groupList } = useGroupList();

  const [schedule, _setSchedule] = useState<ScheduleEdit>({
    title: "新建时间表",
    description: "启明学院1006",
    capacity: 1,
    close_time: -1,
    duration: 10 * 60,
    gap_time: 5 * 60,
    schedule_id: -1,
  });

  const setSchedule = (newSchedule: Partial<ScheduleEdit>) => {
    _setSchedule({ ...schedule, ...newSchedule });
  };

  useEffect(() => {
    if (!self || self.group_id === undefined) return;

    /** 设置表的标题 */
    if (!groupList || groupList.length === 0) return;
    const myGroup = groupList.find((g) => g.id === self.group_id);
    if (!myGroup) return;
    setSchedule({
      title: `${myGroup.name}时间安排 - ${moment().format("YY/M/D")}`,
    });
  }, [self && self.group_id, groupList && groupList.length]);

  return {
    schedule,
    setSchedule,
  };
}
