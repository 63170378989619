import { ScheduleBrief } from "../../../models/schedule";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const getSchedulesOfMyGroupPath = "/schedule";

/**
 * 获得本组的时间安排表
 * @param open 是否筛选开启中的报名表
 * @returns
 */
export async function getSchedulesOfMyGroupReq(
  open = true
): Promise<ScheduleBrief[] | null> {
  await wait(1000);

  // return [
  //   {
  //     id: 1,
  //     title: "前端组第一次时间表",
  //   },
  //   {
  //     id: 2,
  //     title: "前端组第2次时间表",
  //   },
  //   {
  //     id: 3,
  //     title: "前端组第3次时间表",
  //   },
  //   {
  //     id: 4,
  //     title: "前端组第4次时间表",
  //   },
  // ];

  const res = await request<ScheduleBrief[]>({
    url: getSchedulesOfMyGroupPath,
    method: "GET",
    params: {
      open,
    },
  });

  return res;
}
