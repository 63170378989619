import useSWR from "swr";

import {
  getAllowOfRecruitPath,
  getAllowOfRecruitReq,
} from "../../http/group/getAllowOfRecruit";

/** 本组报名表开启还是关闭 */
export function useAllowOfRecruit() {
  const { isValidating, mutate, data, error } = useSWR(
    getAllowOfRecruitPath,
    () => getAllowOfRecruitReq()
  );

  return {
    allowOfRecruit: data,
    loading: data === undefined,
    error: data === null || error,
    mutate,
  };
}

// q: useSWR 返回的 isValidating 和 mutate 有什么用？
// a: mutate 是用来手动触发重新请求的，isValidating 是用来判断是否正在请求中的
