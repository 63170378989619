import "./index.scss";

import ResumeActions from "./ResumeActions";
import ResumeMain from "./ResumeMain";
import ResumeNav from "./ResumeNav";

export default function Resume() {
  return (
    <div className="p-resume">
      <ResumeNav></ResumeNav>
      <ResumeMain></ResumeMain>
      <ResumeActions></ResumeActions>
    </div>
  );
}
