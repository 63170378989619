import { MemberInfo } from "../../../models/member";
import { wait } from "../../../utils/wait";
import request from "../../request";

export const loginPath = "/user/token";

type LoginRes = Pick<
  MemberInfo,
  "association_name" | "nickname" | "identity"
> & { token: string };

type LoginParams = {
  username: string;
  password: string;
};

/**
 * 登录
 * @returns
 */
export async function loginReq(params: LoginParams): Promise<LoginRes | null> {
  const { password, username } = params;

  const res = await request<LoginRes>({
    url: `${loginPath}?username=${username}&password=${password}`,
    method: "GET",
  });

  return res;
}
