import "./index.scss";

import { FC } from "react";

import {
  Col,
  Descriptions,
  Row,
  Skeleton,
  Tag,
  TagGroup,
} from "@douyinfe/semi-ui";
import { Data } from "@douyinfe/semi-ui/lib/es/descriptions";

import { ResumeDetail } from "../../../../../models/resume";
import { ellipsis } from "../../../../../utils/formaters";
import { TagProps } from "@douyinfe/semi-ui/lib/es/tag";

type ApplicantInfoProps = {
  resume: ResumeDetail | undefined | null;
};

const ApplicantInfo: FC<ApplicantInfoProps> = ({ resume }) => {
  if (!resume) {
    return (
      <Skeleton
        active
        placeholder={
          <div style={{ display: "flex" }}>
            <Skeleton.Image className="ra-info-photo-container"></Skeleton.Image>
            <Skeleton.Paragraph
              className="ra-info-item-container"
              rows={5}
            ></Skeleton.Paragraph>
          </div>
        }
      ></Skeleton>
    );
  }

  const description: Data[] = [
    {
      key: "姓名",
      value: resume.name,
    },
    {
      key: "性别",
      value: resume.sex,
    },
    {
      key: "专业",
      value: resume.major,
    },
    {
      key: "年级",
      value: resume.class,
    },
    {
      key: "手机号",
      value: resume.phone,
    },
    {
      key: "邮箱",
      value: resume.email,
    },
    {
      key: "附件",
      value:
        resume.attachment.length === 0 ? (
          <span className="t-2">无</span>
        ) : (
          <TagGroup
            maxTagCount={4}
            showPopover
            tagList={
              resume.attachment.map((f) => ({
                children: (
                  <a target="_blank" href={f.url}>
                    {ellipsis(f.title, 14)}
                  </a>
                ),
                color: "blue",
              })) as TagProps[]
            }
          ></TagGroup>
        ),
    },
  ];

  return (
    <div className="r-applicant-info ra-info">
      <div className="ra-info-photo-container">
        {resume.picture ? (
          <>
            <img className="ra-info-photo" src={resume.picture} />
            <img className="ra-info-photo-blur" src={resume.picture} />
          </>
        ) : (
          <div style={{ opacity: 0.7 }}>未上传</div>
        )}
      </div>

      <div className="ra-info-item-container">
        <Descriptions data={description} />
      </div>
    </div>
  );
};

export default ApplicantInfo;
