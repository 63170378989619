import { Attitude } from "../../../models/resume";
import request from "../../request";

export type SetPeriodRateReqData = {
  attitude: Attitude;
  resumeID: number;
};

/**
 * 在当前轮次对简历打分
 */
export async function setPeriodRateReq({
  attitude,
  resumeID,
}: SetPeriodRateReqData) {
  const res = await request<string>({
    url: `/turn/attitude/${resumeID}`,
    method: "POST",
    data: { attitude },
  });

  return res !== null;
}
