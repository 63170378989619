import "./index.scss";

import { useEffect, useRef, useState } from "react";
import { useSWRConfig } from "swr";

import { Form } from "@douyinfe/semi-ui";
import { FormApi } from "@douyinfe/semi-ui/lib/es/form";

import { getSelfPath } from "../../api/http/member/getSelf";
import { loginReq } from "../../api/http/member/login";
import Button from "../../components/Button";
import { TOKEN_KEY } from "../../constants/token";
import { useJumpTo } from "../../hooks/useJumpTo";
import { useQuery } from "../../hooks/useQuery";
import { showToast } from "../../utils/showToast";
import OidcClient from "./components/OidcClient/index";

type FormFields = {
  username: string;
  password: string;
};

const Login = () => {
  const formApi = useRef<FormApi<FormFields>>();
  const swr = useSWRConfig();

  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const jumpTo = useJumpTo();
  useEffect(() => {
    if (query.hint) {
      showToast(query.hint, "error");
      jumpTo("/login", {}, true);
    }
  }, [query.hint]);

  function validateFields(values: FormFields) {
    const errors: { [K in keyof FormFields]?: string } = {};
    if (!values.password) errors.password = "请输入密码";
    if (!values.username) errors.username = "请输入用户名";

    if (Object.keys(errors).length > 0) return errors;
  }

  async function login() {
    if (!formApi.current || loading) return;

    try {
      await formApi.current.validate();
    } catch (e) {
      return;
    }

    const params = formApi.current.getValues();

    setLoading(true);

    const res = await loginReq({ ...params });

    setLoading(false);

    if (!res) return;

    showToast("登录成功!", "success");
    localStorage.setItem(TOKEN_KEY, res.token);
    swr.mutate(getSelfPath);
    jumpTo("/home", {});
  }

  return (
    <div className="p-login">
      <div className="p-login-cover" />
      <div className="p-login-container">
        <h2 className="p-login-title">冰岩作坊招新系统</h2>
        <Form
          getFormApi={(api) => (formApi.current = api)}
          labelPosition="inset"
          validateFields={
            validateFields as (values: Record<string, unknown>) => string
          } // 是 semi design 逼我的
        >
          <Form.Input field="username" label="用户名" />
          <Form.Input
            onKeyDown={(e) => e.key === "Enter" && login()}
            type="password"
            field="password"
            label="密码"
          />
          <div className="p-login-btn-container">
            <Button loading={loading} onClick={login}>
              登 录
            </Button>
          </div>
        </Form>
        <OidcClient />
      </div>
    </div>
  );
};

export default Login;
