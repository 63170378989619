import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Skeleton } from "@douyinfe/semi-ui";

import { usePeriodDetail } from "../../../../api/swr/association/useCurPeriodInfo";
import { useCurPeriod } from "../../../../hooks/useCurPeriod";
import { PeriodGroupDetail } from "../../../../models/period";
import GroupInfo from "../GroupInfo";
import { useToppedGroups } from "./hooks/useToppedGroups";

const GroupInfoList: FC = () => {
  const { curPeriodInfo, loading } = usePeriodDetail();
  const groups = curPeriodInfo?.detail;

  const { toggleToppedGroup, toppedGroups } = useToppedGroups();
  const toppedGroupsSet = new Set(toppedGroups);

  if (loading || !groups) {
    return (
      <Skeleton
        active
        placeholder={
          <div className="home-overview-content" style={{ padding: 20 }}>
            {Array.from({ length: 5 }).map((_, i) => (
              <Skeleton.Title
                key={i}
                style={{ margin: "1.5rem 0", height: 50 }}
              />
            ))}
          </div>
        }
      />
    );
  }

  return (
    <div className="home-overview-content">
      {/* 先渲染置顶的组 */}
      {toppedGroups
        .map((id) => groups.find((g) => g.group_id === id))
        .filter((g) => !!g)
        .map((group) => (
          <GroupInfo
            {...group!}
            topped={true}
            toggleTopped={() => toggleToppedGroup(group!.group_id)}
            key={group!.group_id}
          />
        ))}
      {/* 再渲染别的组 */}
      {(
        groups.filter(
          (g) => !toppedGroupsSet.has(g.group_id)
        ) as PeriodGroupDetail[]
      ).map((group) => (
        <GroupInfo
          topped={false}
          {...group!}
          toggleTopped={() => toggleToppedGroup(group!.group_id)}
          key={group!.group_id}
        />
      ))}
    </div>
  );
};

export default GroupInfoList;
