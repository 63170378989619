/**
 * 设置安全的水印
 */

type WatermarkOpts = {
  /** 水印颜色 */
  fillStyle?: string;
  fillText: string;
  font?: string;
  textAlign?: CanvasTextAlign;
  textBaseline?: CanvasTextBaseline;
};

const ROTATE = (Math.PI / 180) * -20;
const WIDTH = 300,
  HEIGHT = 200;

export function setWatermark(opts: WatermarkOpts) {
  const {
    fillStyle = "rgba(184, 184, 184, 0.2)",
    font = "16px Microsoft Yahei",
    fillText,
    textAlign = "center",
    textBaseline = "top",
  } = opts;
  const body = document.body;

  const canvas = document.createElement("canvas");

  canvas.setAttribute("width", `${WIDTH}px`);
  canvas.setAttribute("height", `${HEIGHT}px`);

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.font = font;
  ctx.fillStyle = fillStyle;
  ctx.rotate(ROTATE);
  ctx.fillText(fillText, WIDTH * 0.25, HEIGHT * 0.5);
  ctx.fillText(fillText, WIDTH * 0.625, HEIGHT * 1.25);

  const base64Url = canvas.toDataURL();

  const container =
    document.querySelector(".mark_container") || document.createElement("div");
  container.setAttribute("class", "mark_container");

  const containerStyle = `
    display: block !important;
    visibility: visible !important;
    clip-path: none !important;
    clip: none !important;
    position:fixed !important;
    top:0 !important;
    left:0 !important;
    width:100% !important;
    height:100% !important;
    z-index:999 !important;
    pointer-events:none !important;
    background-repeat:repeat !important;
    background-image:url('${base64Url}') !important`;

  container.setAttribute("style", containerStyle);

  body.insertAdjacentElement("afterbegin", container);

  let bodyObserver: MutationObserver | null = new MutationObserver(
    (mutationList, observer) => {
      const container = document.querySelector(".mark_container");
      if (!container) {
        bodyObserver?.disconnect();
        bodyObserver = null;
        setWatermark(opts);
      }
    }
  );

  bodyObserver.observe(body, { childList: true });

  // 检查水印容器与每一个水印的修改
  const containerObserver = new MutationObserver((mutationList) => {
    const mutationItem = mutationList[0];
    // 如果是容器的属性发生变化，将发生变化的容器删除
    if (mutationItem.type === "attributes") {
      body.removeChild(mutationItem.target);
    } else {
      // 否则可直接获取容器进行删
      const container = document.querySelector(".mark_container");
      if (!container) return;
      body.removeChild(container);
    }
  });

  containerObserver.observe(container, {
    attributes: true,
    subtree: true,
    childList: true,
  });
}
