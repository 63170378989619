import type {
  AttachmentItem,
  AttachmentItemData,
  AttachmentParams,
} from "../../../models/attachment";

import request from "../../request";

export const getAttachmentsPath = "/attachment/";

// 从熊学到的代码，把参数的验证放在请求里面，而不是在外部进行。
export async function getAttachments(
  params: AttachmentParams
): Promise<AttachmentItem[] | null> {
  if (!params || typeof params.turn !== "number") return null;

  let res;

  res = await request<AttachmentItemData[]>({
    url: getAttachmentsPath,
    method: "GET",
    params,
  });

  res = res
    ? res.map((item) => {
        return {
          name: item.name,
          attachment: item.attachment?.map((file) => {
            return {
              label: file.title,
              value: file.url,
            };
          }),
        };
      })
    : null;

  return res;
}
