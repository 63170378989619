import "./index.scss";

import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IconChevronLeft,
  IconCrossCircleStroked,
  IconHelpCircle,
  IconPlus,
} from "@douyinfe/semi-icons";
import {
  DatePicker,
  InputNumber,
  Modal,
  Tooltip,
  Input,
} from "@douyinfe/semi-ui";

import Button from "../../components/Button";
import EditableDiv from "../../components/EditableDiv";
import { ScheduleEdit } from "../../models/schedule";
import { useSchedule } from "./hooks/useSchedule";
import { useTimeFrames } from "./hooks/useTimeFrames";
import { useScheduleDetail } from "../../api/swr/schedule/useScheduleDetail";
import {
  createScheduleReq,
  createSchedulePath,
} from "../../api/http/schedule/createSchedule";
import { mutate } from "swr";
import { showToast } from "../../utils/showToast";
import { addTimeframeReq } from "../../api/http/schedule/addTimeframe";
import { getCurTurnSchedulesPath } from "../../api/http/schedule/getCurTurnSchedules";
import { getSchedulesOfMyGroupPath } from "../../api/http/schedule/getSchedulesOfMyGroup";
import { getScheduleDetailPath } from "../../api/http/schedule/getScheduleDetail";
import { deleteAllTimeframeReq } from "../../api/http/schedule/deleteTimeframe";

const useCreateNoticeTime = () => {
  const { schedule, setSchedule } = useSchedule();
  const {
    timeFrames,
    setTimeFrame,
    delTimeFrame,
    addTimeFrame,
    clearTimeFrames,
    hasConflict,
  } = useTimeFrames();
  const { scheduleDetail, loading: loadingSchedule } = useScheduleDetail();

  useEffect(() => {
    if (loadingSchedule) return;
    if (scheduleDetail) {
      const {
        title,
        description,
        capacity,
        duration,
        gap_time,
        close_time,
        id,
        timeframes,
      } = scheduleDetail;
      setSchedule({
        title,
        description,
        capacity,
        duration,
        gap_time,
        close_time: close_time || -1,
        schedule_id: id,
      });

      if (!timeframes) return;
      clearTimeFrames();
      timeframes.forEach((timeframe) =>
        addTimeFrame(timeframe.start_time, 1, {
          title,
          description,
          capacity,
          duration,
          gap_time,
          close_time: close_time || -1,
          schedule_id: id,
        })
      );
    }
  }, [loadingSchedule, scheduleDetail]);

  return {
    scheduleDetail,
    loadingSchedule,
    schedule,
    setSchedule,
    timeFrames,
    setTimeFrame,
    delTimeFrame,
    addTimeFrame,
    clearTimeFrames,
    hasConflict,
  };
};

const CreateNoticeTime = () => {
  const nav = useNavigate();

  const {
    loadingSchedule,
    schedule,
    setSchedule,
    scheduleDetail,
    timeFrames,
    setTimeFrame,
    delTimeFrame,
    addTimeFrame,
    clearTimeFrames,
    hasConflict,
  } = useCreateNoticeTime();

  const [tempDuration, setTempDuration] = useState(schedule.duration);

  //#region 创建 timeFrame 相关数据 👇
  const [shouldShowModal, setShouldShowModal] = useState(false);
  /** 一批面试的开始时间, int, 秒 */
  const [newTimeFrameStartTime, setNewTimeFrameStartTime] = useState<number>(
    Date.now() / 1000
  );
  const [newTimeFrameTurns, setNewTimeFrameTurns] = useState<number>(1);
  const newTimeFrameEndTime =
    newTimeFrameStartTime +
    (newTimeFrameTurns - 1) * schedule.gap_time +
    newTimeFrameTurns * schedule.duration;
  //#endregion

  const handleSave = async () => {
    if (hasConflict) return showToast("时间安排存在冲突", "error");
    const {
      capacity,
      title,
      duration,
      gap_time,
      close_time,
      schedule_id,
      description,
    } = schedule;
    let scheduleId: { schedule_id: number } | null = { schedule_id: -1 };
    if (schedule_id === -1) {
      scheduleId = await createScheduleReq({
        capacity,
        description,
        title,
        duration,
        gap_time,
        close_time,
      });

      if (!scheduleId) return showToast("出错了，请再次尝试", "error");
    } else scheduleId.schedule_id = schedule_id;

    const successList: boolean[] = [];
    const deleteSuccess = await deleteAllTimeframeReq({
      schedule_id: scheduleId.schedule_id,
    });
    successList.push(deleteSuccess);
    for (let i = 0; i < timeFrames.length; i++) {
      const { amount, start_time } = timeFrames[i];
      const success = await addTimeframeReq({
        schedule_id: scheduleId.schedule_id,
        amount,
        start_time,
      });
      successList.push(success);
    }
    const success = successList.every((success) => success);
    if (!success) {
      showToast("出错了，请再次尝试", "error");
      mutate(getCurTurnSchedulesPath);
      return;
    }
    showToast("创建/编辑成功!", "success");
    mutate(getCurTurnSchedulesPath);
    mutate(getSchedulesOfMyGroupPath);
    mutate(`${getScheduleDetailPath}/${schedule_id}`);
  };

  return (
    <>
      {!loadingSchedule ? (
        <div className="p-cnt-wrap">
          <Modal
            width="50rem"
            title="新建时间段"
            visible={shouldShowModal}
            onOk={() => {
              addTimeFrame(
                Math.floor(newTimeFrameStartTime),
                newTimeFrameTurns,
                schedule
              );

              setShouldShowModal(false);

              setNewTimeFrameStartTime(Date.now() / 1000);
              setNewTimeFrameTurns(1);
            }}
            onCancel={() => setShouldShowModal(false)}
            okText={"确认"}
            cancelText={"取消"}
            maskClosable={false}
          >
            <div className="p-cnt-modal">
              <div className="p-cnt-modal-part left">
                <div className="p-cnt-modal-part-title">开始面试时间</div>
                <DatePicker
                  format="yyyy年MM月dd日 HH:mm"
                  value={newTimeFrameStartTime * 1000}
                  type="dateTime"
                  onChange={(result) => {
                    setNewTimeFrameStartTime((result as Date).valueOf() / 1000);
                  }}
                  showClear={false}
                />
              </div>

              <div className="p-cnt-modal-part right">
                <div className="p-cnt-modal-part-title">连续面试场次</div>
                <InputNumber
                  min={1}
                  value={newTimeFrameTurns}
                  onChange={(turns) => setNewTimeFrameTurns(Number(turns) || 1)}
                />
              </div>
            </div>

            <p className="t-2">
              将在{" "}
              {new Date(newTimeFrameStartTime * 1000)
                .toLocaleString()
                .slice(0, -3)}{" "}
              -{" "}
              {new Date(newTimeFrameEndTime * 1000)
                .toLocaleString()
                .slice(-8, -3)}{" "}
              进行面试, 共计面试 {schedule.capacity * newTimeFrameTurns} 人
            </p>
          </Modal>

          <div className="p-create_notice_time p-cnt">
            <div className="p-cnt-header">
              <IconChevronLeft
                onClick={() => nav(-1)}
                className="p-cnt-header-icon"
              />
              <div className="p-cnt-header-title">新建时间安排</div>
              <EditableDiv
                content={schedule.title}
                setContent={(title) => setSchedule({ title })}
              ></EditableDiv>
            </div>
            <div className="p-cnt-des">
              <div>面试地点：</div>
              <EditableDiv
                content={schedule.description}
                setContent={(description) => setSchedule({ description })}
              ></EditableDiv>
            </div>

            <div className="p-cnt-content">
              <div className="p-cnt-content-titles">
                <div className="p-cnt-content-titles-item time_per_interview">
                  <Tooltip content="每场面试的持续时间, 修改此项将重置下方时间段">
                    单场面试时长 <IconHelpCircle />
                  </Tooltip>
                </div>
                <div className="p-cnt-content-titles-item capacity_per_interview">
                  <Tooltip content="每场面试将有多少人同时参加(一般单面设置成 1, 群面设置成群面中每一组的人数)">
                    单场容量 <IconHelpCircle />
                  </Tooltip>
                </div>
                <div className="p-cnt-content-titles-item interview_gap">
                  <Tooltip content="两场面试间面试官有多少休息时间">
                    面试间隔 <IconHelpCircle />
                  </Tooltip>
                </div>
              </div>
              <div className="p-cnt-content-form_row">
                <div
                  className="p-cnt-content-form_row-item time_per_interview"
                  style={{ marginTop: 0 }}
                >
                  <InputNumber
                    min={1}
                    suffix="分钟"
                    hideButtons
                    value={tempDuration / 60}
                    onChange={(val) => {
                      setTempDuration((Number(val) || 0) * 60);
                    }}
                    onBlur={() => {
                      if (tempDuration !== schedule.duration)
                        Modal.confirm({
                          title: "确定改变单场时长吗",
                          content:
                            "改变单场面试时长将清除所有下方已设置的时间段",
                          onOk() {
                            setSchedule({
                              duration: tempDuration,
                            });
                            clearTimeFrames();
                          },
                          onCancel() {
                            setTempDuration(schedule.duration);
                          },
                        });
                    }}
                  />
                </div>
                <div className="p-cnt-content-form_row-item capacity_per_interview">
                  <InputNumber
                    min={1}
                    suffix="人"
                    value={schedule.capacity}
                    onChange={(val) => {
                      setSchedule({
                        capacity: Number(val) || 0,
                      });
                    }}
                  />
                </div>

                <div
                  className="p-cnt-content-form_row-item interview_gap"
                  style={{ marginTop: 0 }}
                >
                  <InputNumber
                    min={1}
                    suffix="分钟"
                    value={schedule.gap_time / 60}
                    onChange={(val) => {
                      setSchedule({
                        gap_time: (Number(val) || 0) * 60,
                      });
                    }}
                  />
                </div>
              </div>
              <>
                <div
                  className="p-cnt-content-titles"
                  style={{ marginBottom: 0 }}
                >
                  <div className="p-cnt-content-titles-item time_zone">
                    <Tooltip
                      content={
                        "不支持编辑(需编辑可删除后重新创建)。按开始时间升序排序"
                      }
                    >
                      计划时间段 <IconHelpCircle />
                    </Tooltip>
                  </div>
                  <div className="p-cnt-content-titles-item capacity">
                    <Tooltip
                      content={
                        "[将覆盖上面的全局配置]每场面试将有多少人同时参加(一般单面设置成 1, 群面设置成群面中每一组的人数)"
                      }
                    >
                      容量 <IconHelpCircle />
                    </Tooltip>
                  </div>
                </div>
                {timeFrames.length === 0 && (
                  <p className="t-2" style={{ textAlign: "center" }}>
                    暂无创建的时间段
                  </p>
                )}
                {timeFrames.map((frame) => {
                  const startTime = new Date(frame.start_time * 1000);
                  const endTime = new Date(
                    (frame.start_time + schedule.duration) * 1000
                  );
                  return (
                    <div
                      className="p-cnt-content-form_row"
                      key={frame.timeframe_id}
                    >
                      <div className="p-cnt-content-form_row-item time_zone">
                        {startTime.toLocaleString().slice(0, -3)} -{" "}
                        {endTime.toLocaleTimeString().slice(0, -3)}
                      </div>
                      <div className="p-cnt-content-form_row-item capacity">
                        <div>{schedule.capacity}</div>
                        <div>人</div>
                      </div>
                      <div
                        className="p-cnt-content-form_row-cancel"
                        onClick={() => delTimeFrame(frame.timeframe_id)}
                      >
                        <IconCrossCircleStroked />
                      </div>
                    </div>
                  );
                })}
                <div
                  className="p-cnt-content-add_row"
                  onClick={() => setShouldShowModal(true)}
                >
                  <IconPlus />
                </div>
              </>
              <div className="p-cnt-actions">
                <Button onClick={handleSave}>保存时间安排表</Button>
                {/* <Button onClick={() => console.log({ schedule })}>
                  删除并重置时间安排表
                </Button> */}
              </div>
              <div>
                注：时间安排表关闭时间在发送通知后，点击通知页“问卷开启中”按钮设置或修改
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CreateNoticeTime;
