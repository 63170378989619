import request from "../../request";

export type DeleteTagReqData = {
  resumeID: number;
  tagID: number;
};

/**
 * 删除简历的标签
 * @returns
 */
export async function deleteTagReq({ resumeID, tagID }: DeleteTagReqData) {
  const res = await request<string>({
    url: `/resume/${resumeID}/tag/${tagID}`,
    method: "DELETE",
  });

  return res !== null;
}
