/** 冰岩成员的登录信息 */
export type MemberInfo = {
  id: number;
  /** 用户名 */
  username: string;
  /** 昵称 */
  nickname: string;
  /** 社团id */
  association_id: number;
  /** 社团名称 */
  association_name: string;
  group_id: number;
  /** 组名称 */
  group_name: string;
  identity: MemberIdentity;
};

export enum MemberIdentity {
  GROUP_MANAGER = "group_manager",
  MEMBER = "member",
}
