import { NoticeTemplateType } from "../../../models/template";
import request from "../../request";

export type EditTemplateReqData = NoticeTemplateType;

export const editTemplatePath = "/notify/template";

/**
 * 修改一份模板
 * @returns
 */
export async function editTemplateReq(data: EditTemplateReqData) {
  const res = await request<string>({
    url: `${editTemplatePath}/${data.template_id}`,
    method: "PUT",
    data,
  });

  return res !== null;
}
