import { ScheduleEdit } from "../../../models/schedule";
import request from "../../request";

export type CreateScheduleReqData = Pick<
  ScheduleEdit,
  "capacity" | "title" | "duration" | "gap_time" | "close_time" | "description"
>;

export const createSchedulePath = "/schedule";

/**
 * 创建一个问卷模板
 * @returns
 */
export async function createScheduleReq(data: CreateScheduleReqData) {
  const res = await request<{ schedule_id: number }>({
    url: createSchedulePath,
    method: "POST",
    data,
  });

  return res;
}
