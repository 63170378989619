import "./index.scss";

import { FC, useEffect, useState } from "react";

import {
  IconChevronDown,
  IconDescend,
  IconMore,
  IconSearch,
} from "@douyinfe/semi-icons";
import { Button, Dropdown, Input, Select, Skeleton } from "@douyinfe/semi-ui";

import { useJumpTo } from "../../../hooks/useJumpTo";
import { useQuery } from "../../../hooks/useQuery";
import { ResumeBrief } from "../../../models/resume";
import NavList from "./components/NavList";
import { useGroup } from "./hooks/useGroup";

type ResumeNavProps = {};

export type CompareResumeFn = Exclude<
  Parameters<Array<ResumeBrief>["sort"]>[0],
  undefined
>;
const SortByOpts = ["报名时间", "拼音升序", "评价降序"] as const;
export type SortByOptsType = typeof SortByOpts[number];
export const sortStrategyMap: Record<SortByOptsType, CompareResumeFn> = {
  拼音升序: (a, b) => a.name.localeCompare(b.name, "zh"),
  评价降序: (a, b) => {
    const INF = 1e6;
    /* if (a.attitude === 0) a.attitude = INF;
    if (b.attitude === 0) b.attitude = INF; */
    return a.attitude - b.attitude; // attitude 值越小评价越高，但是 attitude 为 0 表示未评价，应排在序列最后
  },
  报名时间: (a, b) => 0, // 默认就是报名时间, 不用动
};

const ResumeNav: FC<ResumeNavProps> = () => {
  const {
    curGroup,
    groupList,
    loading: groupListIsLoading,
    setCurGroup,
  } = useGroup();

  const [keyword, setKeyword] = useState("");
  const { sort, kw } = useQuery();
  const jumpTo = useJumpTo();
  useEffect(() => {
    if (kw) setKeyword(kw);
  }, [kw]);

  useEffect(() => {
    if (!sort) jumpTo("/resume", { sort: "报名时间" }, false, true);
  }, [sort]);

  function search(kw?: string) {
    jumpTo(".", { kw: kw === undefined ? keyword : kw }, false, true);
  }

  return (
    <div className="resume-nav">
      <div className="resume-nav-header">
        <Input
          suffix={<IconSearch onClick={() => search()} />}
          className="resume-nav-header-search"
          showClear
          value={keyword}
          onChange={(s) => setKeyword(s)}
          onKeyDown={(e) => e.key === "Enter" && search()}
          onClear={() => search("")}
        ></Input>
        <Button
          className="resume-nav-header-icon"
          icon={<IconMore />}
          aria-label="更多"
          theme="borderless"
          type="tertiary"
        />
      </div>

      <div className="resume-nav-filter">
        <Skeleton
          active
          placeholder={
            <Skeleton.Title style={{ width: "5rem", marginLeft: "0.5rem" }} />
          }
          loading={groupListIsLoading || !curGroup}
        >
          <Skeleton
            active
            placeholder={
              <Skeleton.Title style={{ width: "5rem", marginLeft: "0.5rem" }} />
            }
            loading={groupListIsLoading || !curGroup}
          >
            <Select
              value={curGroup?.name}
              onChange={(id) => setCurGroup(id as number)}
              size="small"
            >
              {(groupList || []).map((g) => (
                <Select.Option value={g.id} key={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
          </Skeleton>
        </Skeleton>

        <div className="spacer"></div>

        <Select
          value={sort}
          onChange={(s) =>
            jumpTo("/resume", { sort: s as string }, false, true)
          }
          size="small"
          suffix={<IconDescend style={{ marginLeft: -8 }} />}
          showArrow={false}
        >
          {SortByOpts.map((o) => (
            <Select.Option value={o} key={o}>
              {o}
            </Select.Option>
          ))}
        </Select>
      </div>

      <NavList></NavList>
    </div>
  );
};

export default ResumeNav;
