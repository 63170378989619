import "./index.scss";

import { useState } from "react";

import {
  IllustrationConstruction,
  IllustrationConstructionDark,
} from "@douyinfe/semi-illustrations";
import { Empty, Modal } from "@douyinfe/semi-ui";

import Button from "../../../../components/Button";
import { useScheduleInfo } from "../../hooks/useScheduleInfo";
import QuestionnaireResult from "../QuestionnaireResult";

type QuesResContainerProps = {
  showManage: boolean;
  onCancel: () => void;
  schedule_id: number;
};

export default function QuesResContainer(props: QuesResContainerProps) {
  const { showManage, onCancel, schedule_id } = props;
  const { results, questionnaireInfo } = useScheduleInfo();

  return (
    <div className="ques_res_container">
      {results &&
      results.questionnaireResults /* && !questionnaireInfo?.open */ ? (
        <QuestionnaireResult
          schedule_id={schedule_id}
          results={results.questionnaireResults}
          notArrange={results.notArrange}
          isOpen={!!(questionnaireInfo && !questionnaireInfo?.open)}
        />
      ) : (
        <Empty
          image={
            <IllustrationConstruction style={{ width: 150, height: 150 }} />
          }
          darkModeImage={
            <IllustrationConstructionDark style={{ width: 150, height: 150 }} />
          }
          title={"暂无数据"}
          description="当前无问卷或未设置问卷时间段"
        />
      )}
      {/* {questionnaireInfo?.closeTime && questionnaireInfo.open && (
        <Modal
          visible={
            Math.floor(Date.now() / 1000) > questionnaireInfo.closeTime &&
            showManage
          }
          onCancel={onCancel}
          centered
          title="安排人员"
        >
          当前问卷已关闭，是否排出人员安排表？
        </Modal>
      )}
      {questionnaireInfo &&
        !questionnaireInfo.closeTime &&
        questionnaireInfo.open && (
          <Modal
            visible={showManage}
            centered
            title="安排人员"
            onCancel={onCancel}
          >
            当前问卷未设置关闭时间，是否立即关闭并排出人员安排表？
          </Modal>
        )} */}
    </div>
  );
}
