import "./index.scss";

import { FC } from "react";

import Button from "../../../../components/Button";
import { NotifyType } from "../../../../models/template";
import { ellipsis } from "../../../../utils/formaters";

type componentsProps = {
  groupName?: string;
  content?: string;
  title: string;
  type: NotifyType;
};

const PopoverItem: FC<componentsProps> = ({
  content,
  groupName,
  title,
  type,
}) => {
  return (
    <div className="create_notice-popover_item">
      <div>
        <div>{title}</div>
        {content && (
          <div className="create_notice-popover_item-content">
            {ellipsis(content, 30)}
          </div>
        )}
      </div>
      <div className="spacer"></div>
      <Button
        content={type === NotifyType.OK ? "通过" : "淘汰"}
        color={type === NotifyType.OK ? "success" : "error"}
        style={{ marginRight: 10 }}
      />
      {groupName && <Button content={groupName} />}
    </div>
  );
};

export default PopoverItem;
