import { Navigate, RouteProps } from "react-router-dom";

import CreateNotice from "../pages/CreateNotice";
import CreateNoticeTime from "../pages/CreateNoticeTime";
import History from "../pages/History";
import Home from "../pages/Home";
import Login from "../pages/Login";
import OidcClient from "../pages/Login/components/OidcClient";
import Notice from "../pages/Notice";
import NoticeTemplate from "../pages/NoticeTemplate";
import Resume from "../pages/Resume";
import AttachmentBox from "../pages/AttachmentBox";

export const routesObj: (RouteProps & {
  cnName?: string;
  protected?: boolean;
})[] = [
  {
    path: "/home",
    element: <Home />,
    cnName: "首页",
    protected: true,
  },
  {
    path: "/resume",
    element: <Resume />,
    protected: true,
    cnName: "简历",
  },
  {
    path: "/home/history",
    protected: true,
    element: <History />,
  },
  {
    path: "/notice",
    element: <Notice />,
    protected: true,
    cnName: "通知",
  },
  // 新加附件箱路由
  {
    path: "/attachment",
    element: <AttachmentBox />,
    protected: true,
    cnName: "附件箱",
  },
  {
    path: "/notice/create",
    protected: true,
    element: <CreateNotice />,
  },
  {
    path: "/notice/create-time",
    protected: true,
    element: <CreateNoticeTime />,
  },
  {
    path: "/notice/template",
    protected: true,
    element: <NoticeTemplate />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Navigate to={"/home"} />,
  },
  {
    path: "/callback",
    element: <OidcClient/>,
  },
];
