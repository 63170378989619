import { useEffect, useState } from "react";

import { useJumpTo } from "../../../../hooks/useJumpTo";
import { useQuery } from "../../../../hooks/useQuery";

const tabKeys = ["快速评价", "聊天室"] as const;
export type TabKey = typeof tabKeys[number];

export function useCurTab() {
  const jumpTo = useJumpTo();

  const actionTab = useQuery().actionTab;

  function setActiveKey(actionTab: TabKey) {
    jumpTo("/resume", { actionTab }, false, true);
  }

  useEffect(() => {
    if (!actionTab) {
      setActiveKey(tabKeys[0]);
    }
  }, [actionTab]);

  return { actionTab, setActiveKey, tabKeys };
}
