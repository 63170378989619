import { TimeFrameEdit } from "../../../models/schedule";
import request from "../../request";

export type DeleteTimeFrameEditReqData = Pick<TimeFrameEdit, "timeframe_id"> & {
  schedule_id: number;
};

export const deleteTimeframePath = "/schedule";

/**
 * 删除面试时间段
 * @returns
 */
export async function deleteTimeframeReq(data: DeleteTimeFrameEditReqData) {
  const res = await request<string>({
    url: `${deleteTimeframePath}/${data.schedule_id}/timeframe/${data.timeframe_id}`,
    method: "DELETE",
    data,
  });

  return res !== null;
}

export type DeleteAllTimeFrameEditReqData = {
  schedule_id: number;
};

/**
 * 删除所有面试时间段
 * @returns
 */
export async function deleteAllTimeframeReq(
  data: DeleteAllTimeFrameEditReqData
) {
  const res = await request<string>({
    url: `${deleteTimeframePath}/${data.schedule_id}/timeframe/all`,
    method: "DELETE",
    data,
  });

  return res !== null;
}
