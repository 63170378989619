import request from "../../request";

export const setAllowOfRecruitPath = "/group/signup";

/**
 * 设置报名表开启关闭
 */
export async function setAllowOfRecruitReq(
  allow_post: boolean
): Promise<string | null> {
  const res = await request<string>({
    url: setAllowOfRecruitPath,
    method: "PUT",
    data: { allow_post },
  });

  return res;
}
