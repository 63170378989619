import "./index.scss";

import { useEffect, useMemo, useState } from "react";

import { DatePicker, Modal } from "@douyinfe/semi-ui";

import Button from "../../../../components/Button";
import { showToast } from "../../../../utils/showToast";
import { countdownFromNow } from "../../utils/countdownFromNow";
import { DialogType } from "../QuestionnaireState";
import { useScheduleDetail } from "../../../../api/swr/schedule/useScheduleDetail";
import { editScheduleReq } from "../../../../api/http/schedule/editSchedule";
import { mutate } from "swr";
import { getCurTurnSchedulesPath } from "../../../../api/http/schedule/getCurTurnSchedules";
import { getSchedulesOfMyGroupPath } from "../../../../api/http/schedule/getSchedulesOfMyGroup";
import { getScheduleDetailPath } from "../../../../api/http/schedule/getScheduleDetail";
import { closeScheduleReq } from "../../../../api/http/schedule/closeSchedule";

export type DialogChangeTimeProps = {
  setDialogType: (type: DialogType) => void;
} & (
  | {
      type: "updateTime";
      closeTime?: number;
    }
  | { type: "setTime" | "closeQuestionnaire" | null }
);

export default function DialogChangeTime(props: DialogChangeTimeProps) {
  const { type, setDialogType } = props;

  const [newCloseTime, setNewCloseTime] = useState<number | null>();
  const { scheduleDetail, loading: loadingScheduleDetail } =
    useScheduleDetail();

  const handleChange = async () => {
    if (!scheduleDetail) return;
    if (!newCloseTime) return showToast("未设置关闭时间", "error");

    const {
      id: schedule_id,
      title,
      description,
      capacity,
      duration,
      gap_time,
    } = scheduleDetail;

    const success = await editScheduleReq({
      close_time: newCloseTime,
      schedule_id,
      title,
      description,
      capacity,
      duration,
      gap_time,
    });

    if (success) {
      showToast("修改成功", "success");
      setDialogType(null);
      mutate(getCurTurnSchedulesPath);
      mutate(getSchedulesOfMyGroupPath);
      mutate(`${getScheduleDetailPath}/${schedule_id}`);
    }
  };

  const handleClose = async () => {
    if (!scheduleDetail) return;
    const { id: schedule_id } = scheduleDetail;
    const success = await closeScheduleReq({ schedule_id });
    if (success) {
      showToast("关闭成功", "success");
      setDialogType(null);
      mutate(getCurTurnSchedulesPath);
      mutate(getSchedulesOfMyGroupPath);
      mutate(`${getScheduleDetailPath}/${schedule_id}`);
    }
  };

  const footer = useMemo(
    () =>
      scheduleDetail ? (
        type === "closeQuestionnaire" ? (
          <>
            <Button content="取消" onClick={() => setDialogType(null)} />
            <Button content="确认" onClick={handleClose} type="filled" />
          </>
        ) : (
          <div>
            <Button
              content="立即关闭问卷"
              onClick={() => setDialogType("closeQuestionnaire")}
            />
            <Button content="确认修改" type="filled" onClick={handleChange} />
          </div>
        )
      ) : null,
    [scheduleDetail, newCloseTime, type]
  );

  let countdownTip = "";
  let updatedCountdown = "";

  if (props.type === "updateTime") {
    const { days, hours, minutes } = countdownFromNow(props.closeTime || 0);
    countdownTip =
      days || hours || minutes
        ? `距离问卷关闭还有${days ? `${days}天` : ""}${
            hours ? `${hours}小时` : ""
          }${minutes ? `${minutes}分钟` : ""}`
        : "问卷即将关闭";
  }

  if (newCloseTime) {
    const { days, hours, minutes } = countdownFromNow(newCloseTime);
    updatedCountdown = `定时后，问卷将于${days ? `${days}天` : ""}${
      hours ? `${hours}小时` : ""
    }${minutes ? `${minutes}分钟` : ""}后自动关闭`;
  }

  if (!type) return <></>;

  return (
    <Modal
      visible
      centered
      className="dialog_change_time"
      footer={footer}
      onCancel={() => {
        setNewCloseTime(null);
        setDialogType(null);
      }}
    >
      <div className="dialog_change_time-content">
        <div>{type === "setTime" ? "问卷正在开启中" : countdownTip}</div>
        <div>
          {type === "setTime"
            ? "是否设置自动关闭时间？"
            : type === "closeQuestionnaire"
            ? "是否关闭问卷"
            : "是否修改关闭时间"}
        </div>
        {type !== "closeQuestionnaire" ? (
          <div className="dialog_change_time-content-date">
            <DatePicker
              type="dateTime"
              format="yyyy年MM月dd日 HH:mm"
              disabledDate={(date) => {
                if (!date) return true;
                const ddl = Date.now() - 1000 * 60 * 60 * 24;
                return date.getTime() < ddl;
              }}
              onChange={(date) => {
                if (date) {
                  const time = Date.parse(date.toString());
                  if (time < Date.now()) {
                    showToast("不能设置为过去时间", "error");
                    return;
                  }
                  setNewCloseTime(() => time);
                }
              }}
              className="dialog_change_time-content-date-picker"
            />
            <div className="dialog_change_time-content-date-tips">
              {updatedCountdown}
            </div>
          </div>
        ) : (
          <div>关闭后不能重新开启</div>
        )}
      </div>
    </Modal>
  );
}
