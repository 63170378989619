/**
 * 将一段内容限制为指定长度
 * @param content 需要处理的文本
 * @param maxLen 超过此长度就省略
 */
export function ellipsis(content: string, maxLen: number) {
  if (content.length < maxLen) return content;

  return content.slice(0, maxLen) + "...";
}
