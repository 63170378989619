import "./index.scss";

import { FC } from "react";

import { Tooltip } from "@douyinfe/semi-ui";

import { ResumeBrief } from "../../../../../../../models/resume";

type NavItemProps = {
  resume: ResumeBrief;
  index: number;
  onClick: () => void;
  active: boolean;
};

const NavItem: FC<NavItemProps> = ({ resume, index, onClick, active }) => {
  return (
    <div
      onClick={onClick}
      className={`r-nav-item ${active ? "active" : ""} ${
        resume.has_out ? "out" : ""
      }`}
    >
      <div className="r-nav-item-index">{index}</div>
      <div className="r-nav-item-name">
        {resume.has_out ? (
          <Tooltip content="已被淘汰">{resume.name}</Tooltip>
        ) : (
          resume.name
        )}
      </div>
    </div>
  );
};

export default NavItem;
