import { Checkbox } from "@douyinfe/semi-ui";
import { CheckboxEvent } from "@douyinfe/semi-ui/lib/es/checkbox";
import axios from "axios";
import Button from "../../../../components/Button";
import { useCurPeriod } from "../../../../hooks/useCurPeriod";
import { BottomBarProps } from "../../../../models/attachment";
import "./index.scss";

// 在要下载的文件名前面拼接这个人的名字
const downloadFile = async (name: string, url: string, periodName: string) => {
  try {
    const res = await axios.get(url, {
      responseType: "blob",
    });

    const fileURL = window.URL.createObjectURL(new Blob([res.data]));

    console.log(url);

    const a = document.createElement("a");
    a.href = fileURL;
    a.download = `${name}-${periodName}-${decodeURIComponent(url.split("attname=").pop() || "未命名附件")}`;
    a.click();

    window.URL.revokeObjectURL(fileURL);
  } catch (e) {
    console.error("下载错误", e);
  }
};

export default function BottomBar({
  checkedValues,
  allAttachmentValues,
  setCheckedValues,
}: BottomBarProps) {
  // 获得周期信息
  const period = useCurPeriod();

  const checkedValuesLength = Object.keys(checkedValues).reduce((acc, cur) => {
    return acc + checkedValues[cur].length;
  }, 0);
  const allAttachmentValuesLength = Object.keys(allAttachmentValues).reduce(
    (acc, cur) => {
      return acc + allAttachmentValues[cur].length;
    },
    0
  );

  const handleCheckBoxChange = (e: CheckboxEvent) => {
    // 全选或者全不选
    setCheckedValues(e.target.checked ? allAttachmentValues : {});
  };

  const handleDownload = () => {
    // 遍历 checkedValues，然后下载
    Object.keys(checkedValues).forEach((key) => {
      checkedValues[key].forEach((value) => {
        downloadFile(key, value, period?.name || "?");
      });
    });
  };

  return (
    <div className="bottom-bar-container">
      <Checkbox
        style={{ marginLeft: "3rem" }}
        onChange={handleCheckBoxChange}
        checked={allAttachmentValuesLength === checkedValuesLength}>
        全选
      </Checkbox>
      <Button
        className="bottom-bar-button"
        type="filled"
        color="primary"
        content={`批量下载（共${checkedValuesLength}个文件）`}
        onClick={handleDownload}></Button>
    </div>
  );
}
