import { FC, ReactElement, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useSelf } from "../api/swr/member/useSelf";
import { TOKEN_KEY } from "../constants/token";
import { MemberIdentity } from "../models/member";
import { showToast } from "../utils/showToast";

const ProtectedRoute: FC = ({ children }) => {
  const token = localStorage.getItem(TOKEN_KEY);
  // token 存在才发送请求
  const { self } = useSelf(!!token);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!self?.identity) return;

    if (
      self.identity !== MemberIdentity.GROUP_MANAGER &&
      location.pathname.startsWith("/notice")
    ) {
      showToast("这个界面只有组长能看哦", "warning");
      navigate("/");
    }
  }, [self?.identity, location.pathname]);

  if (!token) return <Navigate to={"/login?hint=请登录!"}></Navigate>;

  return children as ReactElement | null;
};

export default ProtectedRoute;
