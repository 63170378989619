import "./index.scss";

import { FC } from "react";

import { IconCaretdown, IconTriangleDown } from "@douyinfe/semi-icons";
import { Dropdown, Select, Tag } from "@douyinfe/semi-ui";

import { NotifyType, notifyType2Name } from "../../../../models/template";

type SelectProps = {
  selection?: NotifyType;
  setSelection: (type: NotifyType) => void;
};

const SelectState: FC<SelectProps> = ({ setSelection, selection }) => {
  return (
    <Dropdown
      render={
        <Dropdown.Menu>
          {Object.keys(notifyType2Name).map((type) => (
            <Dropdown.Item
              onClick={() => setSelection(Number(type))}
              key={type}
            >
              <div className={`select_state-opt type-${type}`}>
                {notifyType2Name[Number(type) as NotifyType]}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      }
    >
      <div className={`select_state-content type-${selection}`}>
        {selection === undefined ? "请选择" : notifyType2Name[selection]}
        <IconTriangleDown className="select_state-content-icon" />
      </div>
    </Dropdown>
  );
};

export default SelectState;
