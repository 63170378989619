import { Select } from "@douyinfe/semi-ui";
import { useEffect, useState } from "react";
import { useGroupList } from "../../../../api/swr/group/useGroupList";
import { useSelf } from "../../../../api/swr/member/useSelf";
import { useTurnInfo } from "../../../../api/swr/turn/useTurnInfo";
import { useCurPeriod } from "../../../../hooks/useCurPeriod";
import type {
  NavigationProps,
  Option,
  Selection,
} from "../../../../models/attachment";
import "./index.scss";

/**
 *  Select的直接子元素必须为 Option 或者 OptGroup，不允许为其他Element \
 *  Option的直接子元素必须为字符串，不允许为其他Element
 */
const Option = Select.Option;

const selectionsDefault: Selection[] = [
  {
    label: "组别",
    options: [],
  },
  {
    label: "轮次",
    options: [],
  },
];

export default function Navigation({
  selectedValues,
  setSelectedValues,
}: NavigationProps) {
  const [selections, setSelections] = useState<Selection[]>(selectionsDefault);
  // 获得当前周期参数
  const periodInfo = useCurPeriod();

  // 获取组别列表
  const { groupList } = useGroupList();
  // 获取个人信息
  const { self } = useSelf();
  // 获取轮次列表
  const { turnInfo } = useTurnInfo(selectedValues?.group, periodInfo?.id);

  // 设置组别选项
  useEffect(() => {
    // 没有组别列表就啥都不干
    if (!groupList || groupList.length === 0 || !self) return;

    // 把组别列表转换成选项列表
    const groupOptions = groupList.map((group) => {
      return {
        value: group.id,
        label: group.name,
      };
    });

    // 更新组别选项
    setSelections((selections) => {
      return selections.map((selection) => {
        if (selection.label === "组别") {
          return {
            ...selection,
            options: groupOptions,
          };
        }
        return selection;
      });
    });

    // 设置默认的组别选择值为用户所在的组别
    setSelectedValues({
      group: self?.group_id,
      turn: undefined,
    });
  }, [groupList, self]);

  // 设置轮次选项
  useEffect(() => {
    // 没有轮次列表就啥都不干
    if (!turnInfo || turnInfo.length === 0) return;

    // 把轮次列表转换成选项列表
    const turnOptions = turnInfo.map((turn) => {
      return {
        value: turn.id,
        label: turn.name,
      };
    });

    // 更新轮次选项
    setSelections((selections) => {
      return selections.map((selection) => {
        if (selection.label === "轮次") {
          return {
            ...selection,
            options: turnOptions,
          };
        }
        return selection;
      });
    });

    // 设置默认选择值为最新的一轮
    setSelectedValues((pre) => ({
      ...pre,
      turn: turnInfo[turnInfo.length - 1].id,
    }));
  }, [turnInfo, periodInfo?.id]);

  const selectionsView = selections.map((selection, index) => {
    return (
      <Select
        style={{ width: "80%", backgroundColor: "var(--bg)" }}
        optionList={selection.options}
        insetLabel={selection.label}
        size="large"
        value={index === 0 ? selectedValues?.group : selectedValues?.turn}
        onChange={(value) => {
          if (index === 0) {
            setSelectedValues({
              turn: undefined,
              group: value as number,
            });
          } else {
            setSelectedValues({
              ...selectedValues,
              turn: value as number,
            });
          }
        }}
      />
    );
  });
  return <div className="nav-container">{selectionsView}</div>;
}
