import { Modal } from "@douyinfe/semi-ui";

type DialogUpdateSignupStateProps = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
};

export default function DialogUpdateSignupState(
  props: DialogUpdateSignupStateProps
) {
  return (
    <Modal title="修改报名表状态" {...props}>
      您确认要修改当前报名表状态吗
    </Modal>
  );
}
