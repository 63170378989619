import "./index.scss";

import { observer } from "mobx-react-lite";

import { Table } from "@douyinfe/semi-ui";
import { ColumnProps } from "@douyinfe/semi-ui/lib/es/table";
import Text from "@douyinfe/semi-ui/lib/es/typography/text";

import { useCurInterviewers } from "../../../../api/swr/notify/useCurInterviewers";
import { useCurTurnInfo } from "../../../../api/swr/turn/useCurTurnInfo";
import Button from "../../../../components/Button";
import RateResultBar from "../../../../components/RateResultBar";
import { NotifyListStoreStore } from "../../../../mobx/notifyList";
import { downloadCSV } from "../../../../utils/downloadCSV";
import { showToast } from "../../../../utils/showToast";
import { dataFilter } from "./utils/dataFilter";
import { dataTransformer } from "./utils/dataTransformer";

/** table 渲染需要的数据 */
export type DataType = {
  id: number;
  name: string;
  major: string;
  tags?: {
    id: number;
    tag: string;
  }[];
  score: [number, number, number, number, number];
  state: "success" | "error" | "disabled" | "filled" | "unfilled";
  phone: string;
  schedule: boolean;
};

type InterviewersTableProps = {
  filterValue: string;
};

const buttonContent = {
  success: "通过",
  error: "淘汰",
  disabled: "未通知",
  filled: "已填写",
  unfilled: "未填写",
};

const columns: ColumnProps<DataType>[] = [
  {
    title: "姓名",
    dataIndex: "name",
    width: 90,
    render: (text) => (
      <Text ellipsis={{ showTooltip: true }} style={{ width: 90 }}>
        {text}
      </Text>
    ),
  },
  {
    title: "专业/班级",
    dataIndex: "major",
    width: 150,
    render: (text) => (
      <Text ellipsis={{ showTooltip: true }} style={{ width: 150 }}>
        {text}
      </Text>
    ),
  },
  {
    title: "标签",
    dataIndex: "tags",
    render: (text, record, index) =>
      record.tags && (
        <div className="interviews_table-tags">
          {record.tags.map((tag, index) => (
            <Button content={tag.tag} type="light_filled" key={tag.id} />
          ))}
        </div>
      ),
    width: 200,
  },
  {
    title: "本轮评价",
    dataIndex: "score",
    render: (_, record) => <RateResultBar attitudes={record.score} />,
    width: 200,
  },
  {
    title: "状态",
    dataIndex: "state",
    render: (text: DataType["state"]) => {
      return (
        <Button
          content={buttonContent[text]}
          type="light_filled"
          color={text === "filled" || text === "unfilled" ? "success" : text}
        />
      );
    },
    width: 80,
  },
  {
    title: "问卷",
    dataIndex: "schedule",
    render: (_, record) => (
      <Text ellipsis={{ showTooltip: true }} style={{ width: 90 }}>
        {record.schedule ? "开启中" : ""}
      </Text>
    ),
    width: 90,
  },
];

function _InterviewersTable(props: InterviewersTableProps) {
  const { filterValue } = props;
  const { turnInfo } = useCurTurnInfo();
  const { curTurnInterviewers } = useCurInterviewers({
    turnID: turnInfo?.id,
  });

  const data = curTurnInterviewers
    ? dataFilter(filterValue, dataTransformer(curTurnInterviewers))
    : [];

  function exportCSV() {
    const selected = NotifyListStoreStore.value;

    if (!selected.length) return showToast("未选中任何人", "warning");

    downloadCSV(
      selected.map((s) => [s.name, s.phone]),
      "人员信息"
    );
  }

  return (
    <>
      <Table
        loading={!curTurnInterviewers}
        className="interviews_table"
        columns={columns}
        rowSelection={{
          selectedRowKeys: NotifyListStoreStore.value.map((v) => v.id),
          onChange: (_, rows) => {
            rows &&
              (NotifyListStoreStore.value = rows.map((r) => ({
                id: r.id,
                name: r.name,
                phone: r.phone,
              })));
          },
        }}
        dataSource={data}
        pagination={false}
        rowKey="id"
      ></Table>
      <div className="interviews_table-footer">
        <span className="t-2">
          选中 {NotifyListStoreStore.value.length} / {data.length}
        </span>
        <Button onClick={exportCSV}>导出为 CSV</Button>
      </div>
    </>
  );
}

const InterviewersTable = observer(_InterviewersTable);

export default InterviewersTable;
